import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { AuthContext } from "../context/AuthContext";
import { createCheckoutWithCardElement } from "@paperxyz/js-client-sdk";
import "./styles/cardOrCryptoCheckoutStyle.css";

const QRCode = require("qrcode");

export default function CardOrCryptoCheckout(props) {
    const { user } = useContext(AuthContext);
    const [code, setCode] = useState("");
    const [copied, setCopied] = useState(false);

    useEffect(() => {
        if (user) {
            QRCode.toDataURL(user.address, function (err, url) {
                console.log(url)
                setCode(url);
            });
        }
        getPaperIntent();
    }, []);

    function copyAddress() {
        setCopied(true)
        navigator.clipboard.writeText(user.address)
            .then(() => {
                console.log('Copied to clipboard:');
            })
            .catch(err => {
                console.error('Error copying to clipboard:', err);
            });
    }

    async function getPaperIntent() {
        // setReviewingPayment(true);
        const { data } = await axios.post("/tx/get-paper-intent", { walletAddress: user.address, email: user.email, amount: 25 * 10 ** 6 });
        if (!data.paperData?.sdkClientSecret) {
            // setErrorMsg("Error encountered. Please try again later.");
        } else {
            createCheckoutWithCardElement({
                sdkClientSecret: data.paperData.sdkClientSecret,
                elementOrId: "checkoutCardPaperEl",
                appName: "Venue",
                options: {
                    colorBackground: '#14171A',
                    colorPrimary: '#00fc00',
                    colorText: 'white',
                    borderRadius: 6,
                    inputBackgroundColor: '#1B1F21',
                    inputBorderColor: '#3d4950',
                },
                onError(error) {
                    console.error("Payment error:", error);
                    // setErrorMsg("Error encountered. Please try again later.");
                },
                onPaymentSuccess({ id }) {
                    // localStorage.setItem("txCount", props.txCount + 1);
                    // props.purchaseDone();
                    // setSuccessfulTx(true);
                },
            });
        }
    }


    return (
        <div>
            <div id="modalBlur" onClick={() => props.close()}></div>
            <div className="cardOrCryptoBody">
                <svg onClick={() => props.close()} id="closeBuyKeyModal" xmlns="https://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <div id="buyKeyTitle">
                    Deposit Ether
                </div>
                <div id="cryptoDepositArea">
                    {/* <img id="walletQRImg" src={code}></img> */}
                    <div>
                        <div id="addressCheckFlex">
                            <div id="userAddress">
                                address: <mark id="greenMark">{user?.address || "0x962ffd69147bc20ed205ea3386d4b228ca024430"}</mark>
                            </div>
                            {copied ?
                                <svg id="copyAddressChecker" xmlns="https://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                </svg>
                                :
                                <svg id="copyAddress" onClick={() => copyAddress()} width="24" height="24" fill="none" viewBox="0 0 24 24">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M6.5 15.25V15.25C5.5335 15.25 4.75 14.4665 4.75 13.5V6.75C4.75 5.64543 5.64543 4.75 6.75 4.75H13.5C14.4665 4.75 15.25 5.5335 15.25 6.5V6.5"></path>
                                    <rect width="10.5" height="10.5" x="8.75" y="8.75" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" rx="2"></rect>
                                </svg>
                            }
                        </div>
                        <div id="baseNetworkMsg">
                            base network only
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}