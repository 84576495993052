import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import PersonalActivityTx from "../../components/PersonalActivityTx";
import CollectionElement from "../../components/CollectionElement";
import BuyCard from "../../components/BuyCard";
import HeaderMain from "../../components/HeaderMain";
import WithdrawModal from "./WithdrawModal";
import axios from "axios";
import "./styles/walletPageStyle.css";

export default function WalletPage(props) {
    const [transactions, setTransactions] = useState([]);
    const [walletContent, setWalletContent] = useState("txs");
    const [cardWindow, setCardWindow] = useState(false);
    const [collection, setCollection] = useState([{}, {}]);
    const [withdrawing, setWithdrawing] = useState(false);
    const [totalKeyBalance, setTotalKeyBalance] = useState(0);
    const [copied, setCopied] = useState(false);
    const { user, balance, getBalance, ethPrice, getKeys, userKeys, walletNotification } = useContext(AuthContext);

    useEffect(() => {
        getTransactions();
        getKeys();
        getBalance();
    }, []);

    async function getTransactions() {
        try {
            const { data } = await axios.post("/tx/get-all-txs", { userID: user._id });
            console.log(data);
            setTransactions(data.txs.reverse());
        } catch (error) {
            console.log(error);
        }
    }

    function purchaseDone() {
        setCardWindow(false);
        getTransactions();
    }

    function copyCode() {
        setCopied(true)
        navigator.clipboard.writeText(user.address)
            .then(() => {
                console.log('Copied to clipboard:');
            })
            .catch(err => {
                console.error('Error copying to clipboard:', err);
            });
    }

    function refreshData() {
        getTransactions();
        getKeys();
        getBalance();
    }

    return (
        <div>
            <HeaderMain />
            {withdrawing ? <WithdrawModal cancel={() => setWithdrawing(false)} /> : null}
            {cardWindow ? <BuyCard txCount={transactions.length} close={() => setCardWindow(false)} purchaseDone={() => purchaseDone()} /> : null}
            <div id="walletPage">
                <div id="walletModalTop">
                    <div id="walletTitle">
                        {user?.username}'s wallet
                    </div>
                    <div id="walletInfoArea">
                        <div className="walletInfo">
                            <mark className="walletInfoLabel">Balance:</mark> {(balance / 10 ** 18).toFixed(5)} ETH (${((balance / 10 ** 18) * ethPrice).toFixed(2)})
                        </div>
                        <div className="walletInfo">
                            <mark className="walletInfoLabel">Address:</mark> {user?.address.slice(0, 8) + "..." + user?.address.slice(user?.address.length - 8, user?.address.length)}
                            {copied ?
                                <div id="copyCode">
                                    copied
                                </div>
                                :
                                <div id="copyCode" onClick={() => copyCode()}>
                                    copy
                                </div>
                            }
                        </div>
                        <div id="baseLogoArea">
                            <mark className="walletInfoLabel">Network:</mark> base
                        </div>
                    </div>
                    <div id="walletBtnArea">
                        <div className="walletBtn" onClick={() => setCardWindow(true)}>buy eth</div>
                        <div className="walletBtn" onClick={() => setWithdrawing(true)}>withdraw</div>
                    </div>
                    <div id="walletMenuArea">
                        <div className="walletMenuBtn" onClick={() => setWalletContent("txs")} id={walletContent === "txs" ? "selectedWalletBtn" : ""}>
                            <svg id="walletMenuIcon" xmlns="https://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            <div>
                                transactions
                            </div>
                        </div>
                        <div className="walletMenuBtn walletCollectionBtn" onClick={() => setWalletContent("collection")} id={walletContent === "collection" ? "selectedWalletBtn" : ""}>
                            <svg id="walletMenuIcon" xmlns="https://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
                            </svg>
                            <div>
                                collection
                            </div>
                        </div>
                    </div>
                </div>
                <div id="walletModalBottom">
                    {walletContent === "txs" ?
                        transactions.length > 0 ?
                            transactions.map(tx => {
                                return (
                                    <PersonalActivityTx
                                        tx={tx}
                                        key={tx._id}
                                        username={user?.username}
                                    />
                                )
                            }) :
                            <div id="noTxsMessage">you have 0 transactions</div>
                        :
                        userKeys.length > 0 ?
                            <div>
                                {
                                    userKeys.map((key, index) => {
                                        return (
                                            <CollectionElement
                                                refreshData={() => refreshData()}
                                                keyCount={key.count}
                                                tokenId={key.tokenId}
                                                tokenBalance={key.balance}
                                                totalEarned={key.totalEarned}
                                                collectorCount={key.user.collectorCount}
                                                collectDate={key.createdAt}
                                                ethPrice={ethPrice}
                                                user={key.user}
                                                index={index}
                                                key={key.user._id + index}
                                            />
                                        )
                                    })}
                            </div>
                            :
                            <div id="noTxsMessage">you have 0 collectibles</div>
                    }
                    <div id="walletPageSpacer">

                    </div>
                </div>
            </div>
        </div>
    );
}