import React from "react";
import "./noMatchStyle.css";

export default function NoMatch() {

    return (
        <div id="noMatchFlex">
            <h1>hi friend ( ◠‿◠ )</h1>
            <div>
                {/* love you */}
            </div>
            <h1>
            </h1>
        </div>
    );
}