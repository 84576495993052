import React from "react";
import PersonalActivityTx from "../../components/PersonalActivityTx";
import PreviewComponent from "../../components/PreviewComponent";
import CollectionElement from "../../components/CollectionElement";

export default function AccountContent(props) {
    if (props.selectedTab === "transactions") {
        if (props.transactions.length > 0) {
            return (
                <div >
                    {props.transactions.map(tx => {
                        return (
                            <PersonalActivityTx
                                tx={tx}
                                key={tx._id}
                                username={props.user.username}
                            />
                        )
                    })}
                </div>
            )
        } else {
            return (
                <div id="noCollectiblesMsg">you have 0 transactions</div>
            )
        }
    } else if (props.selectedTab === "collection") {
        if (props.collection.length > 0) {
            return (
                <div>
                    {props.collection.map((key, index) => {
                        return (
                            <CollectionElement
                                keyCount={key.count}
                                tokenId={key.tokenId}
                                tokenBalance={key.balance}
                                totalEarned={key.totalEarned}
                                collectorCount={key.user?.collectorCount}
                                collectDate={key.createdAt}
                                ethPrice={props.ethPrice}
                                user={key.user}
                                index={index}
                                key={key.user._id + index}
                                refreshData={() => props.refreshData()}
                            />
                        )
                    })}
                </div>
            )
        }
        return (
            <div id="noCollectiblesMsg">you have 0 collectibles</div>
        )
    }
    else if (props.selectedTab === "videos") {
        if (props.videos.length > 0) {
            return (
                <div className="contentGrid">
                    {props.videos.map(video => {
                        return (
                            <PreviewComponent
                                key={video._id + "created"}
                                videoID={video._id}
                                price={video.price}
                                accessType={video.accessType}
                                thumb={video.thumbSrc}
                                title={video.title}
                                timestamp={video.timestamp}
                                views={video.viewHistory?.length}
                                latestKeyPrice={video.latestKeyPrice}
                                solEarned={video.solEarned}
                                username={props.username}
                                userPic={props.profileImg}
                                grid={true}
                                collected={true}
                            />
                        )
                    })}
                </div>
            )
        }
    }
}