import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import "./styles/topTippersStyle.css";

export default function TopTippers(props) {
    const history = useHistory();
    const [selectedSupporter, setSelectedSupporter] = useState("");

    const Supporter = (props) => {
        return (
            <div className="tipper" id={selectedSupporter === props.username ? "selectedTipper" : ""} onClick={selectedSupporter === props.username ?() => history.push("/user/" + props.username) : () => setSelectedSupporter(props.username)}>
                <img className="tipperImg" src={props.profileImg} />
                <div className="tipperInfo">
                    <div>
                        <div className="tipperName">
                            {props.username.length > 8 ? props.username.slice(0, 8) + "..." : props.username}
                        </div>
                        <div className="tipperAmount">${props.amount.toFixed(2)}</div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div id="topTippers">
            <div id="topTippersTitle">
                Top Tippers
            </div>
            <div id="topTippersFlex">
                {props.supporters.map(supporter => {
                    return (
                        <Supporter
                            profileImg={supporter.profileImg}
                            username={supporter.username}
                            amount={supporter.amount}
                            key={supporter.address}
                        />
                    )
                })}
            </div>
        </div>
    );
}