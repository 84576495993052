import React, { useEffect, useState } from "react";
import "./styles/confettiStyle.css";
const confetti = require('canvas-confetti');

export default function Confetti(props) {
    var myConfetti = confetti.create(document.getElementById("confettiCanvas"), { resize: true });
    const [isVisible, setIsVisible] = useState(false);
    var colors = ['#bb0000', '#ffffff'];

    var duration = 500 * 1000;
    var animationEnd = Date.now() + duration;
    var defaults = { startVelocity: 40, spread: 360, ticks: 60, zIndex: 0 };

    function randomInRange(min, max) {
        return Math.random() * (max - min) + min;
    }

    useEffect(() => {
        let varX;
        let varY;
        if (props.element) {
            let unlockBtn = document.getElementById(props.element).getBoundingClientRect();
            varX = (unlockBtn.width / 2) / window.innerWidth;
            varY = (unlockBtn.height / 2) / window.innerHeight;
        } else {
            varX = 0.5;
            varY = 0.5;
        }
        setTimeout(() => {
            var timeLeft = animationEnd - Date.now();
            var particleCount = 50 * (timeLeft / duration);
            myConfetti(Object.assign({}, defaults, { zIndex: 40000, particleCount: 100, origin: { x: varX, y: varY } }));
        }, 0);

        setIsVisible(true);
    }, []);

    return (
        <div>
            <canvas id="confettiCanvas" style={{ "position": "fixed", "zIndex": "0", "width": "100vw", "height": "100vh" }}></canvas>
            <div id="confettiBlur">
                <div className={`popUp`} id={isVisible ? "scaled" : null}>
                    <div id="popupTitle">
                        you earned
                    </div>
                    <div id="popupSub">
                        ${props.claimAmount}
                    </div>

                    <svg id="popupIcon" fill="none" viewBox="0 0 24 24">
                        <circle cx="12" cy="12" r="7.25" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M14.25 8.75H11.375C10.4775 8.75 9.75 9.47754 9.75 10.375V10.375C9.75 11.2725 10.4775 12 11.375 12H12.625C13.5225 12 14.25 12.7275 14.25 13.625V13.625C14.25 14.5225 13.5225 15.25 12.625 15.25H9.75" />
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12 7.75V8.25" />
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12 15.75V16.25" />
                    </svg>

                    <div id="claimPopUpBtn" onClick={() => props.closeClaim()}>
                        claim
                    </div>
                </div>
            </div>
        </div>
    );
}