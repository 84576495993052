import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import CardOrCryptoCheckout from "./CardOrCryptoCheckout";
import DepositEth from "./DepositEth";
import { useHistory } from "react-router-dom";
import BuyCard from "./BuyCard";
import axios from "axios";
import "./styles/checkoutWithWallet.css";

export default function CollectWithWallet(props) {
    const history = useHistory();
    const { user, balance, getBalance } = useContext(AuthContext);
    const [btnMessage, setBtnMessage] = useState("checkout");
    const [btnStatus, setBtnStatus] = useState("");
    const [keyPrice, setKeyPrice] = useState(0);
    const [isSuccess, setIsSuccess] = useState(false);

    const [cardOrCrypto, setCardOrCrypto] = useState(false);

    useEffect(() => {
        getBalance();
    }, []);

    useEffect(() => {
        if (parseFloat(balance / 10 ** 18) <= parseFloat(16.25 / props.ethPrice)) {
            setBtnMessage("Deposit eth");
        } else {
            setBtnMessage("checkout")
        }
    }, [balance]);

    useEffect(() => {
        if (isSuccess) {
            setTimeout(() => {
                setBtnStatus("success");
                setTimeout(() => {
                    props.keyPurchaseDone();
                    props.close();
                }, 400);
            }, 0);
            // setLoading(false);
        }
    }, [isSuccess]);

    async function buyKey() {
        setBtnStatus("confirming");
        let userId = props.user._id || props.user.userID
        try {
            const { data } = await axios.post("/tx/buy-user-key", {
                userId,
            });
            console.log(data);
            if (data.success) {
                setIsSuccess(true);
            } else {
                if (data.message.msgBody === "Insufficient Balance") {
                    setBtnMessage("Deposit eth");
                }
            }
        } catch (error) {
            console.log(error.message);
            setBtnStatus("confirming");
            setBtnMessage("Error Occurred");
        }
    }

    if (cardOrCrypto) {
        return (
          <DepositEth close={() => props.close()} />
        );
      }

    return (
        <div>
            <div id="modalBlur" onClick={() => props.close()}></div>
            <div className="walletCheckoutBody">
                <svg onClick={() => props.close()} id="closeBuyKeyModal" xmlns="https://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <div id="buyKeyTitle">
                    Checkout
                </div>
                <div id="collectOption">
                    <img id="collectHero" src={props.user?.heroImg}></img>
                    <div id="collectOptionCover">
                        <img id="collectProfileImg" src={props.user?.profileImg}></img>
                        <div id="collectName">
                            {props.user?.username}
                        </div>

                    </div>
                </div>
                <div id="amounts">
                    <div className="amountRow">
                    <div>price</div><div> $15</div>
                    </div>
                    <div className="amountRow">
                    <div>fees</div><div> $1.25</div>
                        {/* +0.000001 for gas */}
                    </div>
                    {/* <div className="amountRow">
                        <div>gas fees</div><div> ${0.01}</div>
                    </div> */}
                    <div className="amountLine">

                    </div>
                    <div className="amountRow">
                        <div>total</div><div>($16.25) {(16.25 / props.ethPrice).toFixed(5)} ETH</div>
                    </div>
                    <div className="amountRow">
                    <div>wallet balance</div><div id={btnMessage !== "Deposit eth" ? "" : "red"}>{balance > 0 ? `($${(props.ethPrice * (balance / 10 ** 18)).toFixed(2)}) ` + (balance / 10 ** 18).toFixed(5) : 0} ETH</div>
                    </div>
                </div>
                {
                    btnMessage !== "Deposit eth" ?
                        <div onClick={btnStatus !== "confirming" ? () => buyKey() : null} id={btnStatus === "confirming" || btnStatus === "success" ? "continueBtnLoading" : "continueBtn"}>
                            <div id="loaderMessage">
                                {btnMessage}
                            </div>
                            {btnStatus === "confirming" && btnMessage !== "Error Occurred" ?
                                <div className="loaderBtn">

                                </div>
                                :
                                null}
                            {btnStatus === "success" ?
                                <svg id="checker" xmlns="https://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                </svg>

                                :
                                null
                            }
                        </div>
                        :
                        <div id="depositPrompt" onClick={() => history.push("/wallet")}>
                            <div id="fundsBtn">
                                {btnMessage}
                            </div>
                            <svg id={"rightReal"} xmlns="https://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                            </svg>
                        </div>
                }

                {/* <div id={btnMessage !== "Insufficient funds" ? "continueBtnDisabled" : "continueBtn" } onClick={() => console.log(true)}>
                    deposit funds
                </div> */}
                {/* <div id={btnMessage === "Insufficient funds" ? "continueBtnDisabled" : "continueBtn"} onClick={() => console.log(true)}>
                    {btnMessage}
                </div> */}
            </div>
        </div>
    );
}