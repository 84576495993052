import React from "react";
import ProfileActivityTx from "./ProfileActivityTx";
import CollectionElement from "../../components/CollectionElement";
import PreviewComponent from "../../components/PreviewComponent";

export default function ProfileContent(props) {
    if (props.selectedTab === "transactions") {
        if (props.transactions.length > 0) {
            return (
                props.transactions.map(tx => {
                    return (
                        <ProfileActivityTx
                            tx={tx}
                            key={tx._id}
                            username={props.user.username}
                            profileImg={props.user.profileImg}
                        />
                    )
                })
            )
        } else {
            return (
                <div id="noCollectiblesMsg">{props.user.username} has 0 transactions</div>
            )
        }
    } else if (props.selectedTab === "collection") {
        if (props.keys.length > 0) {
            return (
                <div>
                    {props.keys.map((key, index) => {
                        return (
                            <CollectionElement
                                keyCount={key.count}
                                tokenId={key.tokenId}
                                tokenBalance={key.balance}
                                totalEarned={key.totalEarned}
                                collectorCount={key.user.collectorCount}
                                collectDate={key.createdAt}
                                ethPrice={props.ethPrice}
                                user={key.user}
                                index={index}
                                key={key.user._id + index}
                                public={true}
                            />
                        )
                    })}
                </div>
            )
        }
        return (
            <div id="noCollectiblesMsg">{props.user.username} has 0 collectibles</div>
        )
    } else if (props.selectedTab === "videos") {
        if (props.videos.length > 0) {
            return (
                <div className="contentGrid">
                    {props.videos.map(video => {
                        return (
                            <PreviewComponent
                                key={video._id + "created"}
                                videoID={video._id}
                                accessType={video.accessType}
                                collectUser={props.collectUser}
                                price={video.price}
                                thumb={video.thumbSrc}
                                title={video.title}
                                views={video.viewHistory?.length}
                                timestamp={video.timestamp}
                                solEarned={video.solEarned}
                                username={props.username}
                                userPic={props.profileImg}
                                latestKeyPrice={video.latestKeyPrice}
                                grid={true}
                                collected={props.collected}
                            />
                        )
                    })}
                </div>
            )
        } else {
            return (
                <div id="noCollectiblesMsg">{props.user.username} has 0 videos</div>
            )
        }
    } else if (props.selectedTab === "unlocked") {
        return (
            <div className="contentGrid">
                {props.unlockedMedia.map(video => {
                    return (
                        <PreviewComponent
                            key={video._id + "unlocked" + Math.random()}
                            videoID={video._id}
                            price={video.price}
                            thumb={video.thumbSrc}
                            title={video.title}
                            views={video.viewHistory?.length}
                            solEarned={video.solEarned}
                            timestamp={video.timestamp}
                            username={props.username}
                            userPic={props.profileImg}
                            latestKeyPrice={video.latestKeyPrice}
                            grid={true}
                        />
                    )
                })}
            </div>
        )
    } else if (props.selectedTab === "liked") {
        return (
            <div className="contentGrid">
                {props.likedMedia.map(video => {
                    return (
                        <PreviewComponent
                            key={video._id + "liked"}
                            videoID={video._id}
                            price={video.price}
                            thumb={video.thumbSrc}
                            title={video.title}
                            views={video.viewHistory?.length}
                            solEarned={video.solEarned}
                            timestamp={video.timestamp}
                            username={props.username}
                            userPic={props.profileImg}
                            latestKeyPrice={video.latestKeyPrice}
                            grid={true}
                        />
                    )
                })}
            </div>
        )
    }
}