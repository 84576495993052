import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import axios from "axios";
import HeaderMain from "../../components/HeaderMain";
import AuthWindow from "../../components/AuthWindow";
import ProfileContent from "./ProfileContent";
import TopSupporters from "../../components/TopSupporters";
import CollectModal from "../../components/CollectModal";
import "./styles/profileStyle.css";
import BottomNavBlock from "../../components/BottomNavBlock";
import ManageCollectionBtn from "./ManageCollectionBtn";

export default function Profile(props) {
    const history = useHistory();
    const { isAuthenticated, user, ethPrice } = useContext(AuthContext);
    const [authWindow, setAuthWindow] = useState(false);
    const [selectedTab, setSelectedTab] = useState("videos");
    const [loadingContent, setLoadingContent] = useState(false);
    const [collection, setCollection] = useState([]);
    const [transactions, setTransactions] = useState([]);
    const [cards, setCards] = useState([{}, { tokenId: '0', balance: '95' }]);
    const [collecting, setCollecting] = useState(false);
    const [userData, setUserData] = useState({});
    const [videos, setVideos] = useState([]);
    const [unlockedMedia, setUnlockedMedia] = useState([]);
    const [likedMedia, setLikedMedia] = useState([]);
    const [userKeys, setUserKeys] = useState([]);
    const [supporters, setSupporters] = useState([]);

    const [collected, setCollected] = useState(false);

    useEffect(() => {
        if (props.match.params.user) {
            getUserInfo();
            setSelectedTab("videos");
        } else {
            history.push("/home");
        }
    }, [props.match.params.user, isAuthenticated]);

    useEffect(() => {
        if (userData._id) {
            getWalletContent();
        }
    }, [userData]);

    async function getUserInfo() {
        setLoadingContent(true);
        try {
            const res = await axios.post("/user/profile-content", { username: props.match.params.user });
            if (res.data.user) {
                setVideos(res.data.user.videos.reverse());
                setUserData(res.data.user);
                setLoadingContent(false);
                getKeys(res.data.user._id);
                getCollectStatus(res.data.user.keyAddress);
            } else {
                history.push("/home")
            }
        } catch (err) {
            console.log(err);
        }
    }

    async function getCollectStatus(collectAddress) {
        try {
            const { data } = await axios.post("/tx/collected-status", { collectAddress });
            console.log("niffff");
            setCollected(data.collected);
        } catch (error) {
            setCollected(false);
            console.log(error);
        }
    }

    async function getKeys(id) {
        try {
            const { data } = await axios.post("/tx/get-collection", { userId: id });
            console.log(data);
            if (data.keyData) {
                setUserKeys(data.keyData);
            } else {
                setUserKeys([]);
            }
        } catch (error) {
            console.log(error);
        }
    }


    async function getWalletContent() {
        try {
            const { data } = await axios.post("/tx/get-all-txs", { userID: userData._id });
            console.log(data.txs)
            setTransactions(data.txs.reverse());
            getTopSupporters(data.txs);
        } catch (error) {
            console.log(error);
        }
    }

    function getTopSupporters(transactions) {
        const userMap = new Map();

        const filteredArray = transactions.filter(tx => tx.fromAddress.toUpperCase() != userData.address.toUpperCase());

        // loop through each user object and aggregate their payment amount
        filteredArray.forEach(tx => {
            const { from, amount } = tx;
            const { address } = from;
            const currentAmount = userMap.get(address) || 0;
            userMap.set(address, currentAmount + amount);
        });

        // convert the userMap to an array and sort it by payment amount in descending order
        const sortedUsers = Array.from(userMap, ([address, amount]) => {
            const { from } = transactions.find(tx => tx.fromAddress.toUpperCase() === address.toUpperCase());
            const { profileImg, username } = from;
            return { address, amount, profileImg, username };
        }).sort((a, b) => b.amount - a.amount);

        // create a new array of just the user names, taking the first 5
        const topUsers = sortedUsers.slice(0, 5);
        setSupporters(topUsers);
    }

    function closeAndAuth() {
        setCollecting(false);
        setAuthWindow(true);
    }

    async function keyPurchaseDone() {
        setCollecting(false);
        setCollected(true);
        getUserInfo();
    }

    return (
        <div>
            <HeaderMain />
            {authWindow ? <AuthWindow close={() => setAuthWindow(false)} /> : null}
            {collecting ? <CollectModal closeAndAuth={() => closeAndAuth()} user={userData} keyPurchaseDone={() => keyPurchaseDone()} close={() => setCollecting(false)} /> : null}
            <div id="walletFlex">
                <div id="walletArea">
                    <div id="userHeroArea">
                        {collected ?
                            <div id="collectedBtn">
                                <svg id="collectedIcon" xmlns="https://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z" />
                                </svg>
                                collected
                            </div>
                            :
                            <div id="collectBtn" onClick={() => setCollecting(true)}>
                                collect
                            </div>
                        }
                        <img id="walletUserImg" src={userData?.profileImg}></img>
                        {userData?.heroImg ? <img id="walletUserHero" src={userData?.heroImg}></img> : null}
                    </div>
                    <div id="walletUserInfoRow">
                        <div id="walletNameCollect">
                            <div id="walletNameAdress">
                                <div id="walletUserName">{userData?.username}</div>
                            </div>
                        </div>
                    </div>

                    <div id="leftStuff">
                        <div id="userBio">
                            {userData?.bio}
                        </div>
                    </div>

                    <div id="smallStatsOuter">
                        <div id="smallStats">
                            {userData?.solEarned > 0 ? (userData.solEarned / ethPrice).toFixed(4) : 0} eth earned ‧ {userData?.collectorCount} collector{userData?.collectorCount == 1 ? "" : "s"}
                        </div>
                    </div>

                    {collected ? 
                        <div id="viewInCollection" onClick={() => history.push("/wallet")}>
                            view in collection
                            <svg width="15" height="15" fill="none" viewBox="0 0 24 24">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M17.25 15.25V6.75H8.75"></path>
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M17 7L6.75 17.25"></path>
                            </svg>
                        </div>
                        :
                        null
                    }

                    <ManageCollectionBtn />

                    {supporters.length > 0 ?
                        <TopSupporters supporters={supporters} />
                        :
                        null
                    }

                    <div id="walletMenuProfile">
                        <div id="walletMenuProfileTabs">
                            <div onClick={() => setSelectedTab("videos")} className={selectedTab === "videos" ? "selectedWalletTab" : "walletMenuTab"}>
                                <svg id="walletMenuIcon" xmlns="https://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25h-9A2.25 2.25 0 002.25 7.5v9a2.25 2.25 0 002.25 2.25z" />
                                </svg>
                                <div>
                                    videos
                                </div>
                            </div>
                            <div onClick={() => setSelectedTab("transactions")} className={selectedTab === "transactions" ? "selectedWalletTab" : "walletMenuTab"}>
                                <svg id="walletMenuIcon" xmlns="https://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                                <div>
                                    activity
                                </div>
                            </div>
                            <div onClick={() => setSelectedTab("collection")} className={selectedTab === "collection" ? "selectedWalletTab" : "walletMenuTab"}>
                                <svg id="walletMenuIcon" xmlns="https://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                                </svg>
                                <div>
                                    collection
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="walletContent">
                    {loadingContent ? (
                        <div>loading</div>
                    ) : (
                        <ProfileContent
                            selectedTab={selectedTab}
                            cards={cards}
                            transactions={transactions}
                            collection={collection}
                            user={userData}
                            videos={videos}
                            unlockedMedia={unlockedMedia}
                            likedMedia={likedMedia}
                            username={userData.username}
                            profileImg={userData.profileImg}
                            keys={userKeys}
                            ethPrice={ethPrice}
                            collectUser={() => setCollecting(true)}
                            collected={collected}
                        />
                    )}
                </div>
            </div>
            <BottomNavBlock />
        </div>
    );
}