import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import AuthWindow from "./AuthWindow.js";
import CollectWithWallet from "./CollectWithWallet";

import "./styles/collectModalStyle.css";

export default function CollectModal(props) {
    const { ethPrice, user } = useContext(AuthContext);
    const [explainerOpen, setExplainerOpen] = useState(false);
    const [walletCheckout, setWalletCheckout] = useState(false);
    const [sellKeyWindow, setSellKeyWindow] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    useEffect(() => {
        console.log(props.user)
    }, []);

    if (walletCheckout) {
        if (!user) {
            return (
                <AuthWindow close={() => props.close()} />
            )
        }
        return (
            <CollectWithWallet keyPrice={props.keyPrice} ethPrice={ethPrice} keyPurchaseDone={() => props.keyPurchaseDone()} user={props.user} keyAddress={"props.videoData.keyAddress"} close={() => props.close()} />
        );
    }

    return (
        <div>
            <div id="modalBlur" onClick={() => props.close()}></div>
            <div className="manageKeyModal">
                <svg onClick={() => props.close()} id="closeBuyKeyModal" xmlns="https://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <div id="buyKeyTitle">
                    Collect
                </div>
                <div id="collectOption">
                    <img id="collectHero" src={props.user?.heroImg}></img>
                    <div id="collectOptionCover">
                        <img id="collectProfileImg" src={props.user?.profileImg}></img>
                        <div id="collectName">
                            {props.user?.username}
                        </div>
                    </div>
                    {/* <div id="collectLvl">
                        <mark id="lvlText">gold</mark>
                    </div> */}
                </div>
                {/* <div id="collectPreviewPrice">
                    collect price: $15
                </div> */}
                <div id="collectBenefits">
                    <div>
                        benefits
                    </div>
                    <div className="cBenefit">
                        <svg className="cBenefitIcon" width="24" height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="var(--green)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M4.75 6.75C4.75 5.64543 5.64543 4.75 6.75 4.75H17.25C18.3546 4.75 19.25 5.64543 19.25 6.75V17.25C19.25 18.3546 18.3546 19.25 17.25 19.25H6.75C5.64543 19.25 4.75 18.3546 4.75 17.25V6.75Z"></path>
                            <path stroke="var(--green)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M7.75 5V19"></path>
                            <path stroke="var(--green)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M16.25 5V19"></path>
                            <path stroke="var(--green)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M5 8.75H7.5"></path>
                            <path stroke="var(--green)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M17 8.75H19"></path>
                            <path stroke="var(--green)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M5 12H19"></path>
                            <path stroke="var(--green)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M5 15.25H7.5"></path>
                            <path stroke="var(--green)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M17 15.25H19"></path>
                        </svg>
                        <div>
                            exclusive content
                        </div>
                    </div>
                    <div className="cBenefit">
                        <svg className="cBenefitIcon" width="24" height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="var(--green)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M4.75 19.25L12 4.75L19.25 19.25L12 15.75L4.75 19.25Z"></path>
                            <path stroke="var(--green)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12 15.5V12.75"></path>
                        </svg>
                        <div>
                            chat with creator
                        </div>
                    </div>
                    <div className="cBenefit">
                        <svg className="cBenefitIcon" width="24" height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="var(--green)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M4.75 11.25L10.25 5.75"></path>
                            <path stroke="var(--green)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M5.75 19.2502H6.25C6.80229 19.2502 7.25 18.8025 7.25 18.2502V15.75C7.25 15.1977 6.80229 14.75 6.25 14.75H5.75C5.19772 14.75 4.75 15.1977 4.75 15.75V18.2502C4.75 18.8025 5.19772 19.2502 5.75 19.2502Z"></path>
                            <path stroke="var(--green)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M11.75 19.2502H12.25C12.8023 19.2502 13.25 18.8025 13.25 18.2502V12.75C13.25 12.1977 12.8023 11.75 12.25 11.75H11.75C11.1977 11.75 10.75 12.1977 10.75 12.75V18.2502C10.75 18.8025 11.1977 19.2502 11.75 19.2502Z"></path>
                            <path stroke="var(--green)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M17.75 19.2502H18.25C18.8023 19.2502 19.25 18.8025 19.25 18.2502V5.75C19.25 5.19772 18.8023 4.75 18.25 4.75H17.75C17.1977 4.75 16.75 5.19772 16.75 5.75V18.2502C16.75 18.8025 17.1977 19.2502 17.75 19.2502Z"></path>
                            <path stroke="var(--green)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M11.25 8.25V4.75H7.75"></path>
                        </svg>
                        <div>
                            {(20 /props.user?.collectorCount).toFixed(2)}% of creator's earnings
                        </div>
                    </div>
                </div>
                <div id="explainerTitle" onClick={() => window.open("https://basescan.org/address/" + props.user?.keyAddress, "_blank")}>
                    <div>
                        how it works
                    </div>

                    <svg id="learnMoreCollectIcon" xmlns="https://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                    </svg>

                </div>

                <div id={explainerOpen ? "explainerOpen" : "explainerClosed"}>
                    <div id="explainer1" className="explainer">
                        <div className="explainerTopText">
                            Buy a key
                        </div>
                        <div className="explainerText">
                            Key prices go up by 15% after each purchase.
                        </div>
                        <svg id="chartIcon" xmlns="https://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z" />
                        </svg>
                        <div className="explainerBtn" id="selectedExplainer">

                        </div>
                    </div>
                </div>

                <div id="continueBtn" onClick={() => setWalletCheckout(true)}>
                    collect $15
                </div>
            </div>

        </div>
    );
}