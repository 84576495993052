import React, { useEffect, useState } from "react";
import "./styles/paywallOptionsStyle.css";

export default function CollectorOption(props) {
    const [amount, setAmount] = useState(1.00);

    useEffect(() => {
        props.setAmount(amount);
    }, [amount]);

    function changeAmount(e) {
        if (!isNaN(e.target.value)) {
            setAmount(e.target.value)
        }
    }

    return (
        <div id="paywallOptionsArea">
            <div id="paywallExplainer">
                Only your collectors will be able to view this video.
            </div>
            <div id="collectorOptionSub">
                Your collect price is <mark id="collectPriceGateInfo">$15</mark>.
            </div>
        </div>
    );
}