import React, { createContext, useState, useEffect } from 'react';
import axios from "axios";

export const AuthContext = createContext();

export default ({ children }) => {
    const [user, setUser] = useState(null);
    const [balance, setBalance] = useState(0);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [authWindow, setAuthWindow] = useState(false);
    const [notificationArray, setNotificationArray] = useState([]);
    const [readCount, setReadCount] = useState(10000000000);
    const [ethPrice, setEthPrice] = useState(0);
    const [userKeys, setUserKeys] = useState([]);
    const [walletNotification, setWalletNotification] = useState(false);

    useEffect(() => {
        getUser();
        getEthPrice();
    }, []);

    useEffect(() => {
        if(user) {
            getBalance();
            getNotifications();
            getKeys();
            // setInterval(() => {
            //     getNotifications();
            // }, 5000);
        }
    }, [user]);

    async function getUser() {
        try {
            const res = await axios.get("/user/info");
            if (res.status === 200) {
                setUser(res.data.user);
                setIsAuthenticated(true);
                setIsLoaded(true);
                setReadCount(res.data.user.messageReadCount);
            } else {
                setIsAuthenticated(false);
                setIsLoaded(true);
            }
        } catch (error) {
            setIsAuthenticated(false);
            setIsLoaded(true);
        }
    }

    async function getKeys() {
        try {
            const { data } = await axios.post("/tx/get-collection", { userId: user._id });
            if(data?.keyData?.length > 0) {
                let sortedKeys = data.keyData.sort((a, b) => b.balance - a.balance);
                setUserKeys(sortedKeys);
                const keysWithPositiveBalance = sortedKeys.filter(key => ((key.balance / (10 ** 18)) * ethPrice).toFixed(2) > 1);
                setWalletNotification(keysWithPositiveBalance.length)
            } else {
                setUserKeys([]);
            }
        } catch (error) {
            console.log(error);
        }
    }

    async function getNotifications() {
        if(user) {
            try {
                const data = await axios.get("/user/notifications");
                if (data.status === 200) {
                    setNotificationArray(data.data.notifications.reverse());
   
                }
            } catch (error) {
     
            }
        }
    }

    async function getBalance() {
        try {
            const { data } = await axios.get("/tx/get-eth-balance/" + user.address);
            if(data.balance) {
                setBalance(data.balance);   
            } else {
                setBalance(0);
            }
        } catch (error) {
            setBalance(0);
        }
    }

    async function getEthPrice() {
        try {
            const { data } = await axios.get("/tx/get-eth-price");
            if(data.ethUsdPrice) {
                setEthPrice(data.ethUsdPrice);   
            } else {
                setEthPrice(2400);
            }
        } catch (error) {
            setEthPrice(2400);
        }
    }

    async function logout() {
        try {
            const res = await axios.get("/user/logout");
            if (res.data.success) {
                setUser(null);
                setBalance(0);
                setNotificationArray([]);
                setReadCount(0);
                setIsAuthenticated(false);
                localStorage.removeItem("authMethod");
            }
        } catch (err) {
    
        }
    }

    return (
        <div>
            {!isLoaded ? <h1></h1> :
                <AuthContext.Provider value={{ authWindow, setAuthWindow,readCount, getEthPrice, ethPrice, getKeys, setReadCount, user, setUser, balance, setBalance, getBalance, isAuthenticated, setIsAuthenticated, getUser, notificationArray, userKeys, setUserKeys, walletNotification, logout }}>
                    {children}
                </AuthContext.Provider>}
        </div>
    )
}