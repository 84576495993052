import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import axios from "axios";
import HeaderMain from "../../components/HeaderMain";
import Footer from "../../components/Footer";
import AuthWindow from "../../components/AuthWindow";
import TopUser from "./TopUser";
import VideoRow from "./VideoRow";
import VideoRowPlaceHolder from "./VideoRowPlaceHolder";
import UserRowPlaceHolder from "./UserRowPlaceHolder";
import "./styles/homeStyle.css";
import BottomNavBlock from "../../components/BottomNavBlock";

export default function Home(props) {
    const [topUsers, setTopUsers] = useState([]);
    const { ethPrice } = useContext(AuthContext);
    const [topEarningVideos, setTopEarningVideos] = useState([]);
    const [recentVideos, setRecentVideos] = useState([]);
    const [mostViewedVideos, setMostViewedVideos] = useState([]);
    const [authWindow, setAuthWindow] = useState(false);

    useEffect(() => {
        getTopUsers();
        getTopVideos();
    }, []);

    useEffect(() => {
        // invite code stuff
        if (props.match.params.code) {
            localStorage.setItem("code", props.match.params.code);
            // setAuthWindow(true);
        }
    }, [props.match.params.code]);

    async function getTopUsers() {
        try {
            const topUserData = await axios.get("/media/get-top-users");
            setTopUsers(topUserData.data.topEarningUsers);
        } catch (error) {

        }
    }

    async function getTopVideos() {
        try {
            const topEarningVideoData = await axios.get("/media/get-earning-videos");
            setTopEarningVideos(topEarningVideoData.data.topEarningVideos.slice(0, 30));
            const topViewData = await axios.get("/media/get-top-videos-views");
            setMostViewedVideos(topViewData.data.mostViewedVideos.slice(0, 30));
            const recentVideoData = await axios.get("/media/get-recent-videos");
            setRecentVideos(recentVideoData.data.recentVideos.slice(0, 30));
        } catch (error) {

        }
    }

    let numShown = 2;
    function showMoreUsers() {
        numShown += 3; // increase the number of shown components by 3
        const components = document.querySelectorAll('.topUserDiv');
        // show/hide components based on the number shown
        for (let i = 0; i < numShown; i++) {
            if (i < components.length) {
                if (i < numShown) {
                    components[i].style.display = 'block';
                } else {
                    components[i].style.display = 'none';
                }
            }
        }
    }

    return (
        <div>
            <HeaderMain />
            {authWindow ? <AuthWindow close={() => setAuthWindow(false)} /> : null}
            <div id="homePager">
                {true ?
                    <div id="topUserFlex">
                        <div id="topUsersTitle">
                            Top Earning Creators
                        </div>
                        <div id="topUsersRow">
                            {topUsers.map((user, index) => {
                                return (
                                    <TopUser
                                        ethPrice={ethPrice}
                                        index={index}
                                        key={index}
                                        username={user.username}
                                        amount={user.solEarned}
                                        profileImg={user.profileImg}
                                        collectorCount={user.collectorCount}
                                    />
                                )
                            })}
                        </div>
                    </div>
                    :
                    <UserRowPlaceHolder />
                }
                <div id="showMoreUsersBtn" onClick={() => showMoreUsers()}>
                    <div className="showMoreLine">
                    </div>
                    <div id="showMoreIconCircle">
                        <svg xmlns="https://www.w3.org/2000/svg" id="showMoreIcon" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                        </svg>
                    </div>
                    <div className="showMoreLine">
                    </div>
                </div>
                <div id="homeRows">
                    {recentVideos.length > 0 ? <VideoRow title={"New Videos"} type="trending" content={recentVideos} /> : <VideoRowPlaceHolder />}
                    {mostViewedVideos.length > 0 ? <VideoRow title={"Popular Videos"} type="popular" content={mostViewedVideos} /> : <VideoRowPlaceHolder />}
                    {topEarningVideos.length > 0 ? <VideoRow title={"Top Earning Videos"} type="earning" content={topEarningVideos} /> : <VideoRowPlaceHolder />}
                </div>
                <div id="homeSpacer">
                </div>
                <Footer />
                <BottomNavBlock />
            </div>
        </div>
    );
}