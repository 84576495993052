import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import DepositEth from "../../components/DepositEth";
import CardOrCryptoCheckout from "../../components/CardOrCryptoCheckout";
import BuyCard from "../../components/BuyCard";
import axios from "axios";
import "./styles/checkoutWithWallet.css";

export default function CheckoutWithWallet(props) {
    const { user, balance, getBalance } = useContext(AuthContext);
    const [btnMessage, setBtnMessage] = useState("checkout");
    const [keyPrice, setKeyPrice] = useState(0);
    const [isSuccess, setIsSuccess] = useState(false);

    const [cardOrCrypto, setCardOrCrypto] = useState(false);

    let fees = 0.25;

    useEffect(() => {
        if (balance / 10 ** 18 <= (props.videoData.price + fees) / props.ethPrice) {
            setBtnMessage("Insufficient funds");
        } else {
            setBtnMessage("checkout")
        }
    }, [balance]);

    useEffect(() => {
        if (isSuccess) {
            setTimeout(() => {
                setBtnMessage("success");
                setTimeout(() => {
                    props.videoUnlocked();
                    props.close();
                }, 400);
            }, 700);
            // setLoading(false);
        }
    }, [isSuccess]);

    async function unlockVideo() {
        setBtnMessage("confirming");
        try {
            const { data } = await axios.post("/tx/unlock-video", {
                video: props.videoData._id,
            });
            console.log(data);
            if (data.success) {
                setIsSuccess(true);
            } else {
                if (data.message.msgBody === "Insufficient Balance") {
                    setBtnMessage("Insufficient funds");
                }
            }
        } catch (error) {
            console.log(error.message)
            setBtnMessage("Insufficient funds");
        }
    }

    if (cardOrCrypto) {
        return (
          <DepositEth close={() => props.close()} />
        );
      }

    return (
        <div>
            <div id="modalBlur" onClick={() => props.close()}></div>
            <div className="walletCheckoutBody">
                <svg onClick={() => props.close()} id="closeBuyKeyModal" xmlns="https://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <div id="buyKeyTitle">
                    Unlock Video
                </div>
                <div id="checkoutPreview">
                    <img src={props.src} id="checkoutImg"></img>
                    <div id="checkoutTitleSub">
                        <div id="checkoutTitle">
                            {props.videoData.title.slice(0, 22) + "..."}
                        </div>
                        <div id="checkoutSub">
                            by {props.creator.username}
                        </div>
                    </div>
                    {/* <div id="keyLabelCheckout">
                        <svg className="keyIcon" width="24" height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M15 13.25C17.3472 13.25 19.25 11.3472 19.25 9C19.25 6.65279 17.3472 4.75 15 4.75C12.6528 4.75 10.75 6.65279 10.75 9C10.75 9.31012 10.7832 9.61248 10.8463 9.90372L4.75 16V19.25H8L8.75 18.5V16.75H10.5L11.75 15.5V13.75H13.5L14.0963 13.1537C14.3875 13.2168 14.6899 13.25 15 13.25Z"></path>
                            <path stroke="currentColor" d="M16.5 8C16.5 8.27614 16.2761 8.5 16 8.5C15.7239 8.5 15.5 8.27614 15.5 8C15.5 7.72386 15.7239 7.5 16 7.5C16.2761 7.5 16.5 7.72386 16.5 8Z"></path>
                        </svg>
                        <div>
                            {props.videoData.price.toFixed(2)}
                        </div>
                    </div> */}
                </div>
                <div id="amounts">
                    <div className="amountRow">
                        <div>price</div><div>${props.videoData.price.toFixed(2)}</div>
                    </div>
                    <div className="amountRow">
                        <div>fees</div><div> ${fees}</div>
                        {/* +0.000001 for gas */}
                    </div>
                    {/* <div className="amountRow">
                        <div>gas fees</div><div> ${0.01}</div>
                    </div> */}
                    <div className="amountLine">

                    </div>
                    <div className="amountRow">
                        <div>total</div><div>(${fees + props.videoData.price}) {((props.videoData.price + fees) / props.ethPrice).toFixed(5)} ETH</div>
                    </div>
                    <div className="amountRow">
                        <div>wallet balance</div><div id={btnMessage !== "Insufficient funds" ? "" : "red"}>{balance > 0 ? `($${(props.ethPrice * (balance / 10 ** 18)).toFixed(2)}) ` + (balance / 10 ** 18).toFixed(5) : 0} ETH</div>
                    </div>
                </div>
                {
                    btnMessage !== "Insufficient funds" ?
                        <div onClick={btnMessage !== "confirming" ? () => unlockVideo() : null} id={btnMessage === "confirming" || btnMessage === "success" ? "continueBtnLoading" : "continueBtn"}>
                            <div id="loaderMessage">
                                {btnMessage}
                            </div>
                            {btnMessage === "confirming" ?
                                <div className="loaderBtn">

                                </div>
                                :
                                null}
                            {btnMessage === "success" ?
                                <svg id="checker" xmlns="https://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                </svg>

                                :
                                null
                            }
                        </div>
                        :
                        <div id="depositPrompt" onClick={() => setCardOrCrypto(true)}>
                            <div id="fundsBtn">
                                Buy ETH
                            </div>
                            <svg id={"rightReal"} xmlns="https://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                            </svg>
                        </div>
                }

                {/* <div id={btnMessage !== "Insufficient funds" ? "continueBtnDisabled" : "continueBtn" } onClick={() => console.log(true)}>
                    deposit funds
                </div> */}
                {/* <div id={btnMessage === "Insufficient funds" ? "continueBtnDisabled" : "continueBtn"} onClick={() => console.log(true)}>
                    {btnMessage}
                </div> */}
            </div>
        </div>
    );
}