import React, { useEffect, useState } from "react";
import "./styles/ManageCollectionBtn.css";

export default function ManageCollectionBtn(props) {
    const [collected, setCollected] = useState(false);
    const [open, setOpen] = useState("true");

    useEffect(() => {
        if (props.keyBalance > 0) {
            setCollected(true);
        }
    }, [props.keyBalance])

    function openCollectBtn() {
        if (document.getElementById("collectBtnBody").style.height === "240px") {
            document.getElementById("collectBtnBody").style.height = "55px";
            // document.getElementById("collectionInfoArea").style.display = "0";
        } else {
            document.getElementById("collectBtnBody").style.height = "240px";
            // Assuming you have a reference to the element you want to scroll into view
            // const elementToScroll = document.getElementById("buyBtnBody");

            // // Scroll the element into view with the bottom aligned to the bottom of the page
            // elementToScroll.scrollIntoView({ behavior: 'smooth', block: 'end' });
            setTimeout(() => {
                // document.getElementById("collectionInfoArea").style.opacity = "100%";
            }, 100);
        }
    }

    return (
        <div id="manageCollectionWrapper" >
            <div id="collectBtnBody" onClick={() => openCollectBtn()}>
                <div id="keyBtnTop">
                    {/* <img src={stars} id="buyBtnStars"></img> */}
                    {collected ?
                        <svg id="buyBtnIcon" xmlns="https://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z" />
                        </svg>
                        :
                        <svg id="buyBtnIcon" xmlns="https://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z" />
                        </svg>
                    }
                    <div>
                        <div id="buyKeyText">
                            {collected ? "my collection" : "collect"}
                        </div>
                        <div id="buyKeySub">

                            {collected ? "click here to manage collection" : "price: $21"}
                        </div>
                    </div>
                    <svg id="collectBtnIcon" xmlns="https://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                    </svg>
                </div>
                <div className="collectionInfoBg">
                    <div className="collectionInfo">
                        <div className="collectionText">
                            Token price: $21
                        </div>
                        <div className="collectionText">
                            Collectors: 21
                        </div>
                        <div className="collectionText">
                            Royalties claimed: $556
                        </div>
                        {/* <div className="collectionText">
                            Royalties earned: $0
                        </div> */}
                    </div>
                </div>
                <div className="collectionBtnArea">
                    <div className="sellCollectBtn">
                        sell
                    </div>
                    <div className="claimRoyaltiesBtn" onClick={(e) => { e.stopPropagation(); props.collect(); }}>
                        buy                                                                                                                                                                                                                                                                                                           
                    </div>
                </div>
            </div>
        </div>
    );
}