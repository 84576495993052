import React, { useEffect, useState } from "react";
import "./styles/paywallOptionsStyle.css";

export default function PaywallOptions(props) {
    const [amount, setAmount] = useState(1.00);

    useEffect(() => {
        props.setAmount(amount);
    }, [amount]);

    function changeAmount(e) {
        if (!isNaN(e.target.value)) {
            setAmount(e.target.value)
        }
    }

    return (
        <div id="paywallOptionsArea">
            <div id="paywallExplainer">
                This video costs <mark id="dollarText">${amount}</mark> to unlock.
            </div>
            <div id="amountInputFlex">
                <div id="amountCurrency">
                    price: 
                </div>
                <div id="amountInputArea">
                    <input className="createInput" value={amount} onChange={(e) => changeAmount(e)}></input>
                </div>
            </div>
        </div>
    );
}