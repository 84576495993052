import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { useHistory } from "react-router-dom";
import "./styles/buyKeyModalStyle.css";
import "./styles/inviteModalStyle.css";

export default function InviteModal(props) {
    const history = useHistory();
    const [copied, setCopied] = useState(false);
    const { getKeys, userKeys } = useContext(AuthContext);

    useEffect(() => {
        getKeys();
    }, []);

    function copyCode() {
        setCopied(true)
        navigator.clipboard.writeText("https://venue.market/connect/" + props.username)
            .then(() => {
                console.log('Copied to clipboard:');
            })
            .catch(err => {
                console.error('Error copying to clipboard:', err);
            });
    }

    return (
        <div id="buyCardOuter">
            <div id="modalBlur" onClick={() => props.close()}></div>
            <div id="buyCard">
                <svg onClick={() => props.close()} id="closeBuyKeyModal" xmlns="https://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <div id="buyKeyTitle">
                    Invite frens
                </div>
                {/* <div id="collectOption">
                    <img id="collectHero" src={"https://img.freepik.com/free-vector/spectrum-gradient-abstract-background_1048-17050.jpg"}></img>
                    <div id="collectOptionCover">
                        <div id="collectName">
                            invite users, earn money
                        </div>
                    </div>
                </div> */}

                <div id="inviteExplainer">
                    Whenever someone uses your code to signup, they will automatically be added to your collection.
                </div>

                <div id="inviteMembersTitle">
                    <div id="yourInvitesTitle">
                        your invites
                    </div>
                    <div id="yourInvitesTitle">
                        earned
                    </div>
                </div>
                {/* <div id="noInviteMsg">
                    you have 0 invites
                </div> */}
                {userKeys.length > 0 ?
                    <div>
                        {
                            userKeys.map((key, index) => {
                                if (key.invited) {
                                    return (
                                        <div className="inviteMember">
                                            <div className="inviteMemberName" onClick={() => history.push("/user/" + key.user?.username)}>
                                                {key.user?.username}
                                            </div>
                                            <div className="inviteMemberRow">
                                            </div>
                                            <div>
                                                ${key.totalEarned || 0}
                                            </div>
                                        </div>
                                    )
                                }
                            })}
                    </div>
                    :
                    <div id="noInviteMsg">
                        you have 0 invites
                    </div>
                }
                <div id="yourCode">
                    your code: <mark id="inviteCode">{props.username}</mark>
                </div>



                <div id="checkoutBtn" className="inviteBtn" onClick={() => copyCode()}>
                    {copied ? "copied" : "copy invite link"}
                </div>

            </div>
        </div>

    );
}