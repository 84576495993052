import React, { useContext } from "react";
import ScrollToTop from "./scrollToTop";
import PrivateRoute from './hocs/PrivateRoute';
import UnPrivateRoute from './hocs/UnPrivateRoute';
import AVPRoute from './hocs/AVPRoute';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { AlertContext } from "./context/AlertContext";
import "./index.css";

// Pages
import NoMatch from "./pages/NoMatch/NoMatch";
import Home from "./pages/Home/Home";
import Landing from './pages/Landing/Landing';
import Leaderboard from "./pages/Leaderboard/Leaderboard";
import Video from "./pages/Media/Video";
import Profile from "./pages/Profile/Profile";
import Create from './pages/Create/Create';
import Account from "./pages/Account/Account";
import WalletPage from "./pages/Wallet/WalletPage";
import LearnMore from "./pages/Blog/LearnMore";
import Terms from "./pages/Blog/Terms";
import StarsPromo from "./pages/Promo/StarsPromo";

function App() {
  const { alert } = useContext(AlertContext);

  return (
    <div>
      {alert.alertText ?
        <div id="networkAlertArea">
          <div id="alert">
            <div>{alert.alertText}</div>
            <div className="activityLoader"></div>
          </div>
        </div>
        :
        null
      }
      <BrowserRouter>
        <ScrollToTop />
        <Switch>
          <UnPrivateRoute path="/connect/:code" component={Home} />
          <Route exact path="/" component={Home} />
          <Route exact path="/home" component={Home} />
          <Route path="/video/:id" component={Video} />
          <Route exact path="/about" component={Landing} />
          <Route exact path="/blog/etherplay" component={LearnMore} />
          <Route exact path="/terms" component={Terms} />
          <PrivateRoute exact path="/wallet" component={WalletPage} />
          <Route exact path="/home" component={Home} />
          <Route exact path="/leaderboard" component={Leaderboard} />
          <PrivateRoute path="/account" component={Account} />
          <AVPRoute path="/user/:user" component={Profile} />
          <PrivateRoute path="/create" component={Create} />
          <UnPrivateRoute exact path="/connect" component={StarsPromo} />
          <Route path="/" component={NoMatch} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
