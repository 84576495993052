import React, { useEffect, useState, useContext } from 'react';
import axios from "axios";
import gLogo from "../assets/gLogo.png";
import EmailForm from './EmailForm';
import { AuthContext } from "../context/AuthContext";
import { magic } from "../lib/magic";
import { useGoogleLogin } from '@react-oauth/google';
import PhoneForm from './PhoneForm';
import "./styles/authWindowStyle.css";
import star from "../assets/stars2.png";

export default function AuthWindow(props) {
    const { isAuthenticated, setIsAuthenticated, user, setUser, getCardBalances } = useContext(AuthContext);

    const [twitter, setTwitter] = useState("");
    const [address, setAddress] = useState("");
    const [code, setCode] = useState("");
    const [preparedCode, setPreparedCode] = useState(false);
    const [loading, setLoading] = useState(false);

    const [formReady, setformReady] = useState(false);
    const [formLoading, setFormLoading] = useState(false);
    const [formSuccess, setFormSuccess] = useState(false);

    const [newCode, setNewCode] = useState(null);
    const [copied, setCopied] = useState(false);

    const [interimUser, setInterimUser] = useState(null);
    const [authType, setAuthType] = useState("");

    const [authLoading, setAuthLoading] = useState(false);


    useEffect(() => {
        if (twitter && address && code) {
            setformReady(true)
        } else {
            setformReady(false)
        }
    }, [twitter, address, code]);

    useEffect(() => {
        let code = localStorage.getItem("code");
        if (code) {
            setCode(code);
        }
    }, []);

    function initGoogleLogin() {
        setAuthType("g");
        setAuthLoading(true);
        googleLogin();

        // setTimeout(() => {
        //     setAuthType("");
        //     setAuthLoading(false);
        // }, 1000);
    }

    const googleLogin = useGoogleLogin({
        onSuccess: (codeResponse) => verifyToken(codeResponse.access_token),
        onError: (error) => console.log('Login Failed:', error)
    });

    async function verifyToken(token) {
        try {
            const res = await axios.post("/user/googleAuth", { token });
            if (res.status === 201) {
                // setUser(res.data.user);
                // setIsAuthenticated(true);
                setInterimUser(res.data.user);
                localStorage.setItem("authMethod", "google");
            }
        } catch (error) {

        }
    }

    const loginUser = async (email) => {
        setLoading(true)
        try {
            let didToken = await magic.auth.loginWithEmailOTP({
                email: email,
                // redirectURI: new URL('/home', window.location.origin).href,
            });
            const headerConfig = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + didToken,
                }
            };
            const registrationData = {
                email
            };
            try {
                const { data } = await axios.post("/user/register", registrationData, headerConfig);
                if (data.user) {
                    setInterimUser(data.user);
                    // getCardBalances(data.user.address);
                    localStorage.setItem("authMethod", "magic");
                    // history.push('/profile');
                } else {
                    // ERROR HANDLING ==========================================================
                    // ERROR HANDLING ==========================================================
                    // ERROR HANDLING ==========================================================
                    // ERROR HANDLING ==========================================================
                }
            } catch (error) {
                // ERROR HANDLING ==========================================================
                // ERROR HANDLING ==========================================================
                // ERROR HANDLING ==========================================================
                // ERROR HANDLING ==========================================================
            }
        } catch (err) {
            setUser(null);
            setLoading(false);
        }
    };

    async function submitUser() {
        setFormLoading(true);
        // if (code && !localStorage.getItem("codeClaimed")) {
        //     try {
        //         const { data } = await axios.post("/tx/connect-code", { code });
        //         if (data.success) {
        //             localStorage.setItem("codeClaimed", true);
        //         }
        //     } catch (error) {
        //         console.log(error);
        //     }
        // }
        try {
            const keyData = await axios.post("/tx/new-user-key", {code});
            // ERROR HANDLING ========================================
            // if (!keyData.data.success) {
            //     alert("an error occurred");
            // }

            localStorage.setItem("code", "");

            setFormLoading(false);
            setFormSuccess(true);
            setTimeout(() => {
                if (interimUser) {
                    setUser(interimUser);
                    setIsAuthenticated(true);
                    props.close()
                }
            }, 200);
        } catch (error) {
            console.log(error);
        }
    }

    function copyCode() {
        setCopied(true)
        navigator.clipboard.writeText("https://venue.market/apply/" + newCode)
            .then(() => {
                console.log('Copied to clipboard:');
            })
            .catch(err => {
                console.error('Error copying to clipboard:', err);
            });

    }

    const smsLogin = async (phone) => {
        try {
            let didToken = await magic.auth.loginWithSMS({
                phoneNumber: '+1' + phone,
            });
            const headerConfig = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + didToken,
                }
            };
            const registrationData = {
                phone
            };
            try {
                const { data } = await axios.post("/user/register-phone", registrationData, headerConfig);
                if (data.user) {
                    setInterimUser(data.user);
                    // getCardBalances(data.user.address);
                    localStorage.setItem("authMethod", "magic");
                    // history.push('/profile');
                } else {
                    // ERROR HANDLING ==========================================================
                    // ERROR HANDLING ==========================================================
                    // ERROR HANDLING ==========================================================
                    // ERROR HANDLING ==========================================================
                }
            } catch (error) {
                // ERROR HANDLING ==========================================================
                // ERROR HANDLING ==========================================================
                // ERROR HANDLING ==========================================================
                // ERROR HANDLING ==========================================================
            }
        } catch (err) {
            setUser(null);
            setLoading(false);
        }
    }

    return (
        <>
            <div id="modalBlur" onClick={() => props.close()}></div>
            <div id="authModal">
            <svg onClick={() => props.close()} id="closeAuth" xmlns="https://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                {/* {props.match.params?.code ?
                <div id="formBonus" onClick={() => window.open("https://twitter.com/venue_market", "_blank")}>
                    $5
                </div>
                :
                null
            } */}

                <div id="formTitle">
                    Connect
                </div>
                {/* <div id="formSub">
                    connect to venue
                </div> */}
                {/* {props.match.params?.code ?
                <div id="connectionInvestment">
                    <div>
                        $5 credit bonus
                    </div>
                </div>
                :
                null
            } */}

                <div id="formInputs">

                    <div className="connectOptionArea" id={authType === "g" ? "selectedOption" : ""} onClick={!interimUser ? () => initGoogleLogin() : null}>
                        <img id="gSmaller" className="gLogoConnect" src={gLogo}></img>
                        <div id="gLabel">
                            google
                        </div>
                        {authLoading && !interimUser && authType === "g" ?

                            <div className="loaderBtn3">

                            </div>
                            :
                            interimUser && authType === "g" ?
                                <svg id="authChecker" xmlns="https://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                </svg>
                                : null
                        }
                    </div>
                    {authType === "e" && !interimUser ?
                        <EmailForm onEmailSubmit={loginUser} />
                        :
                        <div className="connectOptionArea" id={authType === "e" ? "selectedOption" : ""} onClick={!interimUser ? () => setAuthType("e") : null}>
                            <svg className="gLogoConnect" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M4.75 7.75C4.75 6.64543 5.64543 5.75 6.75 5.75H17.25C18.3546 5.75 19.25 6.64543 19.25 7.75V16.25C19.25 17.3546 18.3546 18.25 17.25 18.25H6.75C5.64543 18.25 4.75 17.3546 4.75 16.25V7.75Z"></path>
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M5.5 6.5L12 12.25L18.5 6.5"></path>
                            </svg>
                            <div id="gLabel">
                                email
                            </div>
                            {interimUser && authType === "e" ?
                                <svg id="authChecker" xmlns="https://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                </svg>
                                : null}
                        </div>
                    }
                    {authType === "p" && !interimUser ?
                        <PhoneForm onEmailSubmit={smsLogin} />
                        :
                        <div className="connectOptionArea" id={authType === "p" ? "selectedOption" : ""} onClick={!interimUser ? () => setAuthType("p") : null}>
                            <svg className="gLogoConnect" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M8.89286 4.75H6.06818C5.34017 4.75 4.75 5.34017 4.75 6.06818C4.75 13.3483 10.6517 19.25 17.9318 19.25C18.6598 19.25 19.25 18.6598 19.25 17.9318V15.1071L16.1429 13.0357L14.5317 14.6468C14.2519 14.9267 13.8337 15.0137 13.4821 14.8321C12.8858 14.524 11.9181 13.9452 10.9643 13.0357C9.98768 12.1045 9.41548 11.1011 9.12829 10.494C8.96734 10.1537 9.06052 9.76091 9.32669 9.49474L10.9643 7.85714L8.89286 4.75Z"></path>
                            </svg>
                            <div id="gLabel">
                                phone
                            </div>
                            {interimUser && authType === "p" ?
                                <svg id="authChecker" xmlns="https://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                </svg>
                                : null}
                        </div>
                    }

                    <div>

                        <div className="formLabel">
                            invite code
                        </div>
                        <div className="formInputArea">
                            {/* <div id="twitterLabel">
                                    @
                                </div> */}
                            <input value={code} onChange={preparedCode ? null : (e) => setCode(e.target.value)} className="formInput"></input>
                        </div>
                    </div>

                </div>
                {newCode ?
                    <div id="referralZone">
                        <div id="linktext">
                            your <mark id="linkmark" onClick={() => window.open("https://venue.market/apply/" + newCode, "_blank")}>link</mark>
                        </div>
                        {copied ?
                            <svg id="copyCodechecker" xmlns="https://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                            </svg>
                            :
                            <svg id="copyCode" onClick={() => copyCode()} width="24" height="24" fill="none" viewBox="0 0 24 24">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M6.5 15.25V15.25C5.5335 15.25 4.75 14.4665 4.75 13.5V6.75C4.75 5.64543 5.64543 4.75 6.75 4.75H13.5C14.4665 4.75 15.25 5.5335 15.25 6.5V6.5"></path>
                                <rect width="10.5" height="10.5" x="8.75" y="8.75" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" rx="2"></rect>
                            </svg>
                        }
                    </div>
                    : null
                }
                <div id="terms">
                    by proceeding on this site you agree to our <mark id="termsLink" onClick={() => window.open("https://venue.market/terms", "_blank")}>terms</mark> :)
                </div>
                <div id={formLoading || formSuccess ? "formLoading" : interimUser ? "formSubmitBtnReady" : "formSubmitBtn"} onClick={interimUser ? () => submitUser() : null}>
                    <div id="formBtnText">
                        {formLoading ? "loading" : formSuccess ? "success" : "continue"}
                    </div>
                    {formSuccess ?
                        <svg id="checker" xmlns="https://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                        </svg>

                        :
                        null
                    }
                    {formLoading ?
                        <div className="loaderBtn3">

                        </div>
                        :
                        null}
                </div>

            </div>
        </>
    )
}