import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./styles/previewComponentStyle.css";

export default function PreviewComponent(props) {
    const history = useHistory();
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {

    }, []);

    if (props.accessType === "gate") {
        if (props.collected) {
            return (
                <div className={props.index === 0 ? "firstPreviewOuter" : `previewOuter preview${props.type}`} id={props.grid ? "gridPreview" : null}>
                    <div className="topPreviewComponent" id={props.grid ? "topPreviewComponentGrid" : null} onClick={props.collected ? () => history.push("/video/" + props.videoID || "6237e2558c3d2831b47afffd") : () => props.collectUser()}>
                        <div className="previewImgDiv" id={props.grid ? "gridPreviewImg" : null}>
                            <img onLoad={() => setLoaded(true)} className="previewImg" src={props.thumb} style={loaded ? { display: "initial" } : { display: "none" }}></img>
                            <div className="previewPriceTag">
                                COLLECTORS ONLY
                            </div>
                        </div>

                        <div className="previewInfo">
                            <div className="previewInfoLeft">
                                <div className="previewTitle">
                                    {props.title.length > 32 ? props.title.slice(0, 32) + "..." : props.title}
                                </div>
                                <div className="previewViews">
                                    <div className="fadeElement">
                                    </div>
                                    <svg width="19.2" height="19.2" fill="none" viewBox="0 0 24 24">
                                        <path stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M19.25 12C19.25 13 17.5 18.25 12 18.25C6.5 18.25 4.75 13 4.75 12C4.75 11 6.5 5.75 12 5.75C17.5 5.75 19.25 11 19.25 12Z"></path>
                                        <circle cx="12" cy="12" r="2.25" stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></circle>
                                    </svg>
                                    <div>
                                        {props.views || 0}
                                    </div>
                                </div>
                            </div>

                            <div className="previewInfoRight">
                                <div className="previewUser">
                                    <img className="previewUserPic" src={props.userPic}></img>
                                    <div className="previewUserName">
                                        {props.username}
                                    </div>
                                </div>
                                <div className="previewTime">
                                    {props.timestamp || "00:00"}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className={props.index === 0 ? "firstPreviewOuter" : `previewOuter preview${props.type}`} id={props.grid ? "gridPreview" : null}>
                    <div className="topPreviewComponent" id={props.grid ? "topPreviewComponentGrid" : null} onClick={props.collected ? () => history.push("/video/" + props.videoID || "6237e2558c3d2831b47afffd") : () => props.collectUser()}>

                        <div className="previewImgDiv" id={props.grid ? "gridPreviewImg" : null}>
                            <div className="collectorsMask">
                                <svg id="collectedIconThumb" xmlns="https://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z" />
                                </svg>
                                Collectors Only
                            </div>
                            <img onLoad={() => setLoaded(true)} className="previewImg" src={props.thumb} style={loaded ? { display: "initial" } : { display: "none" }}></img>
                            <div className="previewPriceTag">
                                COLLECT NOW
                            </div>
                        </div>

                        <div className="previewInfo">
                            <div className="previewInfoLeft">
                                <div className="previewTitle">
                                    {props.title.length > 32 ? props.title.slice(0, 32) + "..." : props.title}
                                </div>
                                <div className="previewViews">
                                    <div className="fadeElement">
                                    </div>
                                    <svg width="19.2" height="19.2" fill="none" viewBox="0 0 24 24">
                                        <path stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M19.25 12C19.25 13 17.5 18.25 12 18.25C6.5 18.25 4.75 13 4.75 12C4.75 11 6.5 5.75 12 5.75C17.5 5.75 19.25 11 19.25 12Z"></path>
                                        <circle cx="12" cy="12" r="2.25" stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></circle>
                                    </svg>
                                    <div>
                                        {props.views || 0}
                                    </div>
                                </div>
                            </div>

                            <div className="previewInfoRight">
                                <div className="previewUser">
                                    <img className="previewUserPic" src={props.userPic}></img>
                                    <div className="previewUserName">
                                        {props.username}
                                    </div>
                                </div>
                                <div className="previewTime">
                                    {props.timestamp || "00:00"}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }

    return (
        <div className={props.index === 0 ? "firstPreviewOuter" : `previewOuter preview${props.type}`} id={props.grid ? "gridPreview" : null}>
            <div className="topPreviewComponent" id={props.grid ? "topPreviewComponentGrid" : null} onClick={() => history.push("/video/" + props.videoID || "6237e2558c3d2831b47afffd")}>
                <div className="previewImgDiv" id={props.grid ? "gridPreviewImg" : null}>
                    <img onLoad={() => setLoaded(true)} className="previewImg" src={props.thumb} style={loaded ? { display: "initial" } : { display: "none" }}></img>
                    <div className="previewPriceTag">
                        {props.price > 0 ? "$" + props.price.toFixed(2) : "FREE"}
                    </div>
                </div>

                <div className="previewInfo">
                    <div className="previewInfoLeft">
                        <div className="previewTitle">
                            {props.title.length > 32 ? props.title.slice(0, 32) + "..." : props.title}
                        </div>
                        <div className="previewViews">
                            <div className="fadeElement">

                            </div>
                            <svg width="19.2" height="19.2" fill="none" viewBox="0 0 24 24">
                                <path stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M19.25 12C19.25 13 17.5 18.25 12 18.25C6.5 18.25 4.75 13 4.75 12C4.75 11 6.5 5.75 12 5.75C17.5 5.75 19.25 11 19.25 12Z"></path>
                                <circle cx="12" cy="12" r="2.25" stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></circle>
                            </svg>
                            <div>
                                {props.views || 0}
                            </div>
                        </div>

                    </div>

                    <div className="previewInfoRight">

                        <div className="previewUser">
                            <img className="previewUserPic" src={props.userPic}></img>
                            <div className="previewUserName">
                                {props.username}
                            </div>
                        </div>
                        <div className="previewTime">
                            {props.timestamp || "00:00"}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}