import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import AuthContext from "./context/AuthContext";
import Web3Context from "./context/Web3Context";
import AlertContext from './context/AlertContext';
import { GoogleOAuthProvider } from '@react-oauth/google';

ReactDOM.render(
    <GoogleOAuthProvider clientId="386703781468-3b5c69i2l43d1f7aqkbjp3ohb0f0mbe3.apps.googleusercontent.com">
        <AuthContext>
            <AlertContext>
                <Web3Context>
                    <App />
                </Web3Context>
            </AlertContext>
        </AuthContext >
    </GoogleOAuthProvider>,
    document.getElementById('root'));

