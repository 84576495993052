import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import DepositEth from "../../components/DepositEth";
import BuyCard from "../../components/BuyCard";
import axios from "axios";
import "./styles/paywallModalStyle.css";
import "./styles/tipModalStyle.css";

export default function PaywallModal(props) {
  const { balance, getBalance } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [btnMessage, setBtnMessage] = useState("checkout");
  const [done, setDone] = useState(false);
  const [balanceError, setBalanceError] = useState(false);
  const [selectedTipAmount, setSelectedTipAmount] = useState(null);
  const [customTipAmount, setCustomTipAmount] = useState("");
  const [cardOrCrypto, setCardOrCrypto] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    setBtnMessage("checkout");
    let tipAmount = 0;
    if (selectedTipAmount > 0) {
      tipAmount = selectedTipAmount;
    } else if (customTipAmount > 0) {
      tipAmount = customTipAmount;
    }
    if (parseFloat(tipAmount) > (props.ethPrice * (balance / 10 ** 18)).toFixed(2)) {
      setBtnMessage("Insufficient funds");
    }
  }, [selectedTipAmount, customTipAmount]);

  useEffect(() => {
    getBalance();
  }, []);

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        setBtnMessage("success");
        setTimeout(() => {
          props.sentTip();
          props.close();
        }, 400);
      }, 100);
      // setLoading(false);
    }
  }, [isSuccess]);

  async function sendTip() {
    setBtnMessage("confirming");
    let tipAmount = 0;
    if (selectedTipAmount > 0) {
      tipAmount = selectedTipAmount;
    } else if (parseFloat(customTipAmount) > 0) {
      tipAmount = customTipAmount
    }
    if (tipAmount > 0) {
      if (parseFloat(tipAmount) > (props.ethPrice * (balance / 10 ** 18)).toFixed(2)) {
        setBtnMessage("Insufficient funds");
      } else {
        try {
          const { data } = await axios.post("/tx/send-tip", {
            video: props.videoData._id,
            amount: tipAmount
          });
          if (data.success) {
            setIsSuccess(true);
          } else {
            if (data.message.msgBody === "Insufficient Balance") {
              setBtnMessage("Insufficient funds");
            }
          }
        } catch (error) {
          console.log(error.message)
          setBtnMessage("Insufficient funds");
        }
      }
    }
  }

  // async function sendTip() {
  //   // setLoading(true);
  //   let tipAmount = 0;
  //   if (selectedTipAmount > 0) {
  //     tipAmount = selectedTipAmount;
  //   } else if (customTipAmount > 0) {
  //     tipAmount = customTipAmount
  //   }

  //   if (tipAmount > 0) {
  //     if (tipAmount > (props.ethPrice * (balance / 10 ** 18)).toFixed(2)) {
  //       setBalanceError(true);
  //     } else {
  //       // send that tip baby
  //       setLoading(true);
  //       try {
  //         const { data } = await axios.post("/tx/send-tip", {
  //           video: props.videoData._id,
  //           amount: tipAmount
  //         });
  //         console.log(data);
  //         if (data.success) {
  //           props.sentTip();
  //           props.close();
  //         } else {
  //           if (data.message.msgBody === "Insufficient Balance") {
  //             setBalanceError(true);
  //             setLoading(false);
  //           } else {
  //             alert("Insufficient Balance");
  //             props.close();
  //           }
  //         }
  //       } catch (error) {
  //         console.log(error.message);
  //         alert("error occurred");
  //         props.close();
  //       }
  //     }
  //   }
  // }

  function customTipEntered(amount) {
    if (!isNaN(amount)) {
      // amount = parseFloat(amount).toFixed(2);
      setCustomTipAmount(amount);
      setSelectedTipAmount(amount);
    }
  }

  if (cardOrCrypto) {
    return (
      <DepositEth close={() => props.close()} />
    );
  }

  return (
    <>
      <div id="modalBlur" onClick={() => props.close()}></div>
      <div id="paywallModalBody">
        <svg onClick={() => props.close()} id="closeAuth" width="24" height="24" fill="none" viewBox="0 0 24 24">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M4.75 12C4.75 7.99594 7.99594 4.75 12 4.75V4.75C16.0041 4.75 19.25 7.99594 19.25 12V12C19.25 16.0041 16.0041 19.25 12 19.25V19.25C7.99594 19.25 4.75 16.0041 4.75 12V12Z"></path>
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M9.75 9.75L14.25 14.25"></path>
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M14.25 9.75L9.75 14.25"></path>
        </svg>
        <div id="paywallModalTitle">
          Tip {props.username ? props.username : "user"}
        </div>
        <div id="tipAmountRow">
          <div onClick={() => setSelectedTipAmount(1)} id={selectedTipAmount === 1 ? "selectedTipAmount" : ""} className="tipAmountOption">$1</div>
          <div onClick={() => setSelectedTipAmount(5)} id={selectedTipAmount === 5 ? "selectedTipAmount" : ""} className="tipAmountOption">$5</div>
          <div onClick={() => setSelectedTipAmount(25)} id={selectedTipAmount === 25 ? "selectedTipAmount" : ""} className="tipAmountOption">$25</div>
        </div>
        <div id="tipOrFlex">
          <div className="tipOrLine">

          </div>
          <div>
            or
          </div>
          <div className="tipOrLine">

          </div>
        </div>
        <div id="customTipTitleArea">
          <div id="customTipTitle">
            Custom Amount
          </div>
          <div id="tipBalance">
            {/* balance: {balance > 0 ? `($${(props.ethPrice * (balance / 10 ** 18)).toFixed(2)}) ` + (balance / 10 ** 18).toFixed(5) : 0} ETH */}
            balance: {`$${(props.ethPrice * (balance / 10 ** 18)).toFixed(2)} `}
          </div>
        </div>
        <div id="customTipInputArea">
          <input type="number" id="customTipInput" value={selectedTipAmount} onChange={(e) => customTipEntered(e.target.value)}></input>
        </div>
        {btnMessage === "Insufficient funds" ?
          <div id="tipBalanceError">
            {btnMessage}
          </div>
          :
          null
        }
        {
          btnMessage !== "Insufficient funds" ?
            <div onClick={btnMessage !== "confirming" && (selectedTipAmount || customTipAmount) ? () => sendTip() : null} id={btnMessage === "confirming" || (!selectedTipAmount && !customTipAmount) || btnMessage === "success" ? "continueBtnLoading" : "continueBtn"}>
              <div id="loaderMessage">
                {(btnMessage === "checkout" && (selectedTipAmount || customTipAmount)) ? "send $" + (selectedTipAmount || customTipAmount) + " tip" : (!selectedTipAmount && !customTipAmount) ? "send tip" : btnMessage}
              </div>
              {btnMessage === "confirming" ?
                <div className="loaderBtn">

                </div>
                :
                null}
              {btnMessage === "success" ?
                <svg id="checker" xmlns="https://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                </svg>

                :
                null
              }
            </div>
            :
            <div id="depositPrompt" onClick={() => setCardOrCrypto(true)}>
              <div id="fundsBtn">
                deposit eth
              </div>
              <svg id={"rightReal"} xmlns="https://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
              </svg>
            </div>
        }
      </div>
    </>
  );
}