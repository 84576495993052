import React from "react";
import { useHistory } from "react-router-dom";
import "./styles/topUserStyle.css";

export default function TopUser(props) {
    const history = useHistory();

    return (
        <div className={props.index === 0 ? "firstTopUserDiv" : "topUserDiv"} onClick={() => history.push("/user/" + props.username)}>
            <div className="topUserFlex">
                <div className="topUserRank">
                    {props.index + 1}
                </div>
                <img className="topUserPic" src={props.profileImg}></img>
                <div className="topUserInfo">
                    <div>
                        <div className="topUserName">
                            {props.username.length > 15 ? props.username.slice(0,14) + "..." : props.username}
                        </div>
                        <div className="topUserAmount">
                            {props.amount > 0 ? (props.amount / props.ethPrice).toFixed(4) : 0} eth earned
                        </div>
                    </div>
                </div>
                <div className="topUserCollectorCount">
                    <svg id="countUserIcon" xmlns="https://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                    </svg>
                    <div>
                        {props.collectorCount}
                    </div>
                </div>
            </div>
        </div>
    );
}