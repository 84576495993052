import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import CheckoutWithWallet from "./CheckoutWithWallet";
import moment from "moment";
import HeaderMain from "../../components/HeaderMain";
import VideoPlayer from "./VideoPlayer";
import axios from "axios";
import VideoContent from "./VideoContent";
import AuthWindow from "../../components/AuthWindow";
import TipModal from "./TipModal";
import PreviewComponent from "../../components/PreviewComponent";
import MainChart from "../../components/CreatorChart";
import CollectModal from "../../components/CollectModal";
import TopTippers from "../../components/TopTippers";

import "./styles/videoStyle.css";
import BottomNavBlock from "../../components/BottomNavBlock";

export default function Video(props) {
    const history = useHistory();
    const { user, balance, getBalance, ethPrice } = useContext(AuthContext);
    const [authWindow, setAuthWindow] = useState(false);
    const [videoSrc, setVideoSrc] = useState("");
    const [videoData, setVideoData] = useState();
    const [sending, setSending] = useState(false);
    const [liked, setLiked] = useState(false);
    const [creator, setCreator] = useState([]);
    const [selectedTab, setSelectedTab] = useState("comments");
    const [likeCount, setLikeCount] = useState(0);
    const [videoTips, setVideoTips] = useState([]);
    const [videoTxs, setVideoTxs] = useState([]);
    const [solEarned, setSolEarned] = useState(0);
    const [loading, setLoading] = useState(false);
    const [unlockModal, setUnlockModal] = useState(false);
    const [collectingToUnlock, setCollectingToUnlock] = useState(false);
    const [paywallModal, setPaywallModal] = useState(false);
    const [collecting, setCollecting] = useState(false);
    const [accessParameters, setAccessParameters] = useState({ price: 69 });
    const [cardWindow, setCardWindow] = useState(false);
    const [refreshChart, setRefreshChart] = useState(0);
    const [relatedContent, setRelatedContent] = useState([]);
    const [unlocking, setUnlocking] = useState(false);

    const [collected, setCollected] = useState(false);

    useEffect(() => {
        if (props.match.params.id) {
            setVideoSrc();
            getVideoData();
        }
    }, [props.match.params.id, user]);

    useEffect(() => {
        if (videoSrc && user) {
            updateViewCount();
        }
    }, [videoSrc]);

    async function getVideoData() {
        try {
            const res = await axios.post("/media/get-video-data", { videoID: props.match.params.id, user });
            if (res.data?.video && res.data?.creator) {
                const { video, creator } = res.data;
                setVideoData(video);
                setCreator(creator);
                setLikeCount(video.likes?.length || 0);
                setAccessParameters({ chain: video.chain, accessType: video.accessType, accessToken: video.accessToken, price: video.price });
                getVideoTxs(video._id);
                genRelatedContent(creator.userID);
                checkUnlockStatus();
                getCollectStatus(creator.keyAddress);
                if (video.videoSrc) {
                    setVideoSrc(video.videoSrc);
                }
                if (user) {
                    setLiked(video.likes.find(like => like === user._id.toString()));
                }
            } else {
                history.push("/home");
            }
        } catch (error) {
            console.log(error)
            history.push("/home");
        }
    }

    async function getCollectStatus(collectAddress) {
        try {
            const { data } = await axios.post("/tx/collected-status", { collectAddress });
            console.log(data);
            setCollected(data.collected);
        } catch (error) {
            setCollected(false);
            console.log(error);
        }
    }

    async function checkUnlockStatus() {
        try {
            const { data } = await axios.post('/media/get-video-src', { videoID: props.match.params.id });
            console.log(data)
            if (data.videoSrc) {
                setVideoSrc(data.videoSrc);
            } else {
                setVideoSrc("");
            }
        } catch (error) {
            console.log(error)
            // setVideoSrc("");
        }
    }

    // remove this shit!!!!!!!!!!!!
    // async function getTips(id) {
    //     try {
    //         const data = await axios.post('/tx/get-tips', { videoID: id });
    //         setVideoTips([]);
    //         if (data.data?.txs) {
    //             setVideoTips(data.data.txs.slice(0, 5))
    //             console.log(data.data.txs.slice(0, 5))
    //         }
    //     } catch (error) {

    //     }
    // }

    function getTopTippers(transactions) {
        const userMap = new Map();

        // const filteredArray = transactions.filter(tx => tx.fromAddress.toUpperCase() != userData.address.toUpperCase());

        // loop through each user object and aggregate their payment amount
        transactions.forEach(tx => {
            const { from, amount } = tx;
            const { address } = from;
            const currentAmount = userMap.get(address) || 0;
            userMap.set(address, currentAmount + amount);
        });

        // convert the userMap to an array and sort it by payment amount in descending order
        const sortedUsers = Array.from(userMap, ([address, amount]) => {
            const { from } = transactions.find(tx => tx.fromAddress.toUpperCase() === address.toUpperCase());
            const { profileImg, username } = from;
            return { address, amount, profileImg, username };
        }).sort((a, b) => b.amount - a.amount);

        // create a new array of just the user names, taking the first 5
        const topUsers = sortedUsers.slice(0, 5);
        setVideoTips(topUsers);
    }

    async function getVideoTxs(id) {
        try {
            const data = await axios.post('/tx/get-video-txs', { videoID: id });
            if (data.data?.txs) {
                setVideoTxs(data.data.txs);
                getTopTippers(data.data.txs);
            }
        } catch (error) {

        }
    }

    async function genRelatedContent(creatorID) {
        try {
            // get creator's other videos
            const creatorContent = await axios.post("/media/creator-videos", { creatorID });
            // remove current video from array
            let contentArray = creatorContent.data.document.filter(video => video._id !== props.match.params.id).slice(0, 6);

            // get recent videos
            const newContent = await axios.get("/media/get-recent-videos");
            // remove creator's own videos
            const filteredVideos = newContent.data.recentVideos.filter(video => video.user?._id !== creatorID).slice(0, 12);

            // concat and set
            contentArray = contentArray.concat(filteredVideos);
            setRelatedContent(contentArray);
        } catch (error) {

        }
    }

    async function createLike() {
        if (user) {
            setLiked(!liked);
            if (liked) {
                setLikeCount(likeCount - 1)
            } else {
                setLikeCount(likeCount + 1)
            }
            try {
                await axios.post("/media/like", { videoId: props.match.params.id, });
            } catch (error) {
                console.log(error);
            }
        } else {
            setAuthWindow(true);
        }
    }

    async function updateViewCount() {
        try {
            await axios.post("/media/new-view", { videoID: props.match.params.id, userID: user._id });
        } catch (error) {
            console.log(error);
        }
    }

    function closePaywallModal() {
        setPaywallModal(false);
        setLoading(false);
    }

    async function unlockVideo() {
        if (user) {
            setUnlocking(true);
        } else {
            setAuthWindow(true);
        }
    }

    function collectUser(unlocking) {
        if (user) {
            if (unlocking) {
                setCollectingToUnlock(true);
            } else {
                setCollectingToUnlock(false);
            }
            setCollecting(true);
        } else {
            setAuthWindow(true);
        }
    }

    function sentTip() {
        setRefreshChart((refreshChart) => refreshChart + 1);
        getVideoTxs(videoData._id);
    }

    function sendTip() {
        if (user) {
            setSending(true)
        } else {
            setAuthWindow(true);
        }
    }

    function closeAndAuth() {
        setCollecting(false);
        setAuthWindow(true);
    }

    async function keyPurchaseDone() {
        setCollecting(false);
        setCollected(true);
        getVideoData();
    }

    return (
        <div>
            <HeaderMain />
            {unlocking ? <CheckoutWithWallet ethPrice={ethPrice} videoUnlocked={() => checkUnlockStatus()} creator={creator} videoData={videoData} keyAddress={videoData.keyAddress} title={videoData.title} src={videoData.thumbSrc} close={() => setUnlocking(false)} /> : null}
            {collecting ? <CollectModal closeAndAuth={() => closeAndAuth()} user={creator} keyPurchaseDone={() => keyPurchaseDone()} close={() => setCollecting(false)} /> : null}
            {authWindow ? <AuthWindow close={() => setAuthWindow(false)} /> : null}
            {sending && videoData ?
                <TipModal
                    ethPrice={ethPrice}
                    sentTip={() => sentTip()}
                    username={creator.username}
                    userID={creator.userID}
                    userAddress={creator.userAddress}
                    videoData={videoData}
                    close={() => setSending(false)}
                />
                : null}

            <div id="videoPage">
                <div id="innerContent">

                    <div id="playerContainer">
                        {videoSrc ?
                            <VideoPlayer src={videoSrc} />
                            :
                            <div id="paywallSpace">
                                {/* <div id="paywallAccessIcon">
                                    <svg className="keyIcon" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M15 13.25C17.3472 13.25 19.25 11.3472 19.25 9C19.25 6.65279 17.3472 4.75 15 4.75C12.6528 4.75 10.75 6.65279 10.75 9C10.75 9.31012 10.7832 9.61248 10.8463 9.90372L4.75 16V19.25H8L8.75 18.5V16.75H10.5L11.75 15.5V13.75H13.5L14.0963 13.1537C14.3875 13.2168 14.6899 13.25 15 13.25Z"></path>
                                        <path stroke="currentColor" d="M16.5 8C16.5 8.27614 16.2761 8.5 16 8.5C15.7239 8.5 15.5 8.27614 15.5 8C15.5 7.72386 15.7239 7.5 16 7.5C16.2761 7.5 16.5 7.72386 16.5 8Z"></path>
                                    </svg>
                                    locked
                                </div> */}
                                {videoData?.thumbSrc ? <img id="videoThumbBlurred" src={videoData.thumbSrc}></img> : null}
                                <div id="paywallMsg">

                                    <svg width="19.2" height="19.2" fill="none" viewBox="0 0 24 24">
                                        <path stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M5.75 11.75C5.75 11.1977 6.19772 10.75 6.75 10.75H17.25C17.8023 10.75 18.25 11.1977 18.25 11.75V17.25C18.25 18.3546 17.3546 19.25 16.25 19.25H7.75C6.64543 19.25 5.75 18.3546 5.75 17.25V11.75Z"></path>
                                        <path stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M7.75 10.5V10.3427C7.75 8.78147 7.65607 7.04125 8.74646 5.9239C9.36829 5.2867 10.3745 4.75 12 4.75C13.6255 4.75 14.6317 5.2867 15.2535 5.9239C16.3439 7.04125 16.25 8.78147 16.25 10.3427V10.5"></path>
                                    </svg>
                                    <div id="paywallText">
                                        {accessParameters?.accessType === "gate" ? "collect " + creator?.username + " to unlock this video" : "This video costs $" + videoData?.price.toFixed(2) + " to unlock"}
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <div id="videoRow1">
                        {/* <div id="videoInfo">
                            <div id="videoTitle">
                                {videoData?.title || "VIDEO TITLE"}
                            </div>
                        </div> */}
                        <div id="videoStats">

                            <div className="videoStat" id="likeBtn" onClick={() => createLike()}>
                                <svg id="likeIcon" width="19.2" height="19.2" fill={liked ? "red" : "none"} viewBox="0 0 24 24">
                                    <path fill-rule="evenodd" stroke={liked ? "red" : "white"} stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M11.995 7.23319C10.5455 5.60999 8.12832 5.17335 6.31215 6.65972C4.49599 8.14609 4.2403 10.6312 5.66654 12.3892L11.995 18.25L18.3235 12.3892C19.7498 10.6312 19.5253 8.13046 17.6779 6.65972C15.8305 5.18899 13.4446 5.60999 11.995 7.23319Z" clip-rule="evenodd"></path>
                                </svg>
                                <div id="viewNum">
                                    {likeCount}
                                </div>
                            </div>
                            <div className="videoStat">
                                <svg width="19.2" height="19.2" fill="none" viewBox="0 0 24 24">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M19.25 12C19.25 13 17.5 18.25 12 18.25C6.5 18.25 4.75 13 4.75 12C4.75 11 6.5 5.75 12 5.75C17.5 5.75 19.25 11 19.25 12Z"></path>
                                    <circle cx="12" cy="12" r="2.25" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></circle>
                                </svg>
                                <div id="viewNum">
                                    {videoData?.viewHistory?.length || 0}
                                </div>
                            </div>
                            <div className="videoStat">
                                <svg width="19.2" height="19.2" fill="none" viewBox="0 0 24 24">
                                    <circle cx="12" cy="12" r="7.25" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M14.25 8.75H11.375C10.4775 8.75 9.75 9.47754 9.75 10.375V10.375C9.75 11.2725 10.4775 12 11.375 12H12.625C13.5225 12 14.25 12.7275 14.25 13.625V13.625C14.25 14.5225 13.5225 15.25 12.625 15.25H9.75" />
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12 7.75V8.25" />
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12 15.75V16.25" />
                                </svg>
                                <div id="viewNum">
                                    {videoData ? (videoData.solEarned).toFixed(2) : "0.00"}
                                </div>
                            </div>
                        </div>
                        <div id="tipUnlockBtnArea">
                            {/* <div id="likeBtn" onClick={() => createLike()}>
                                like
                            </div> */}
                            {videoSrc ?
                                <div id="tipBtn" onClick={() => sendTip()}>
                                    <div className="unlockIconFlex">
                                        {/* <svg className="unlockBtnIcon" width="19.2" height="19.2" fill="none" viewBox="0 0 24 24">
                                            <circle cx="12" cy="12" r="7.25" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></circle>
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M14.25 8.75H11.375C10.4775 8.75 9.75 9.47754 9.75 10.375V10.375C9.75 11.2725 10.4775 12 11.375 12H12.625C13.5225 12 14.25 12.7275 14.25 13.625V13.625C14.25 14.5225 13.5225 15.25 12.625 15.25H9.75"></path>
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12 7.75V8.25"></path>
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12 15.75V16.25"></path>
                                        </svg> */}


                                        <svg id="myKeysIcon" width="19.2" height="19.2" fill="none" viewBox="0 0 24 24">
                                            <circle cx="12" cy="12" r="7.25" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M14.25 8.75H11.375C10.4775 8.75 9.75 9.47754 9.75 10.375V10.375C9.75 11.2725 10.4775 12 11.375 12H12.625C13.5225 12 14.25 12.7275 14.25 13.625V13.625C14.25 14.5225 13.5225 15.25 12.625 15.25H9.75" />
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12 7.75V8.25" />
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12 15.75V16.25" />
                                        </svg>

                                        <div className="btnText">
                                            send tip
                                        </div>
                                    </div>
                                </div>
                                :
                                <div id="tipBtn" onClick={videoData?.accessType === "gate" ? () => setCollecting(true) : () => unlockVideo()}>
                                    {loading ?
                                        <div id="loaderFlex">
                                            <div className="loaderAuth">
                                            </div>
                                        </div>
                                        :
                                        <div className="unlockIconFlex">
                                            <svg className="unlockBtnIcon" width="19.2" height="19.2" fill="none" viewBox="0 0 24 24">
                                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M5.75 11.75C5.75 11.1977 6.19772 10.75 6.75 10.75H17.25C17.8023 10.75 18.25 11.1977 18.25 11.75V17.25C18.25 18.3546 17.3546 19.25 16.25 19.25H7.75C6.64543 19.25 5.75 18.3546 5.75 17.25V11.75Z"></path>
                                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M7.75 10.5V10.3427C7.75 8.78147 7.65607 7.04125 8.74646 5.9239C9.36829 5.2867 10.3745 4.75 12 4.75C13.6255 4.75 14.6317 5.2867 15.2535 5.9239C16.3439 7.04125 16.25 8.78147 16.25 10.3427V10.5"></path>
                                            </svg>
                                            <div className="btnText">
                                                unlock
                                            </div>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                    <div id="videoUser">
                        <div id="videoUserFlex">
                            <img onClick={() => history.push("/user/" + creator?.username)} id="videoUserPic" src={creator?.profileImg}></img>
                            <div>
                                <div onClick={() => history.push("/user/" + creator?.username)} id="videoUsername">
                                    {creator?.username || "USER"}
                                </div>
                                <div id="videoUserCollectorCount">
                                    {creator?.solEarned > 0 ? (creator?.solEarned / ethPrice).toFixed(4) : 0} eth earned
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="videoInfo">
                        <div id="videoTitle">
                            {videoData?.title || "VIDEO TITLE"}
                        </div>
                    </div>
                    <div id="videoDescription">
                        {videoData?.description}
                    </div>
                    <div id="videoCreatedAt">
                        {moment(videoData?.createdAt).format("LLL")}
                    </div>

                    {/* {videoTips.length > 0 ?
                        <TopTippers supporters={videoTips} />
                        :
                        null
                    } */}



                    {videoTips.length > 0 ?
                        <TopTippers supporters={videoTips} />
                        :
                        null
                    }

                    <div id="videoTabsFlex">
                        <div id="videoTabs">
                            <div id="videoTabBtns">
                                <div onClick={() => setSelectedTab("comments")} className={selectedTab === "comments" ? "selectedVideoTab" : "videoTab"} id="videosTab">
                                    comments
                                </div>
                                <div onClick={() => setSelectedTab("activity")} className={selectedTab === "activity" ? "selectedVideoTab" : "videoTab"} id="videosTab">
                                    activity
                                </div>
                                <div onClick={() => setSelectedTab("related")} className={selectedTab === "related" ? "selectedVideoTab" : "videoTab"} id="relatedContentTab">
                                    related
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="videoContentFlex">
                        <div id="videoContentArea">
                            {videoData ?
                                <VideoContent unlocked={videoSrc ? true : false} videoData={videoData} relatedContent={relatedContent} selectedTab={selectedTab} txs={videoTxs} />
                                :
                                <div>
                                    loading
                                </div>
                            }
                        </div>
                    </div>
                </div>

                <div id="rightVideoContent">
                    <div id="creatorStatsTitle">
                        Creator Stats
                    </div>
                    <div id="creatorStats">
                        <MainChart platform={"uniswap"} creatorId={creator?.userID} refreshChart={refreshChart} />
                        <div id="creatorStatsRow">
                            <img id="creatorStatsImg" src={creator?.profileImg} onClick={() => history.push("/user/" + creator?.username)}></img>
                            <div id="creatorStatsName" onClick={() => history.push("/user/" + creator?.username)}>
                                {creator?.username}
                            </div>
                            {user?.username === creator?.username ? null :
                                collected ?
                                    <div id="statsCollected">
                                        <svg id="collectedIcon" xmlns="https://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z" />
                                        </svg>
                                        collected
                                    </div>
                                    :
                                    <div id="statsCollect" onClick={() => collectUser()}>
                                        collect
                                    </div>
                            }
                        </div>

                        <div id="chartOuter"><canvas id="myChart"></canvas></div>
                    </div>
                    <div id="relatedVideosTitle">
                        Related Videos
                    </div>
                    <div className="relatedVideoGrid">
                        {relatedContent.map((video, index) => {
                            return (
                                <PreviewComponent
                                    type={props.type}
                                    index={index}
                                    key={video._id + props.type}
                                    videoID={video._id}
                                    price={video.price}
                                    thumb={video.thumbSrc}
                                    title={video.title}
                                    views={video.viewHistory?.length}
                                    solEarned={video.solEarned}
                                    timestamp={video.timestamp}
                                    username={video.user?.username || video.name}
                                    userPic={video.user?.profileImg || video.profileImg}
                                    latestKeyPrice={video.latestKeyPrice}
                                    grid={true}
                                />
                            )
                        })}
                    </div>
                </div>
                <div id="relatedVideoFade">

                </div>
            </div>
            <BottomNavBlock />
        </div>
    );
}