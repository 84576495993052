import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import diamond from "../assets/diamondIcon.png";
import axios from "axios";
import gold from "../assets/goldIconWhite.png";
import Confetti from "./Confetti";
import "./styles/collectionElementStyle.css";

export default function CollectionElement(props) {
    const history = useHistory();
    const [open, setOpen] = useState(false);
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [claimAmount, setClaimAmount] = useState(0);

    useEffect(() => {
        setClaimAmount(((props.tokenBalance / (10 ** 18)) * props.ethPrice).toFixed(2));
    }, []);

    function openCollection(id) {
        if (document.getElementById("collectionItem" + id).style.height === "255px") {
            document.getElementById("collectionItem" + id).style.height = "64px";
            document.getElementById("collectionInfoArea" + id).style.opacity = "0";
        } else {
            document.getElementById("collectionItem" + id).style.height = "255px";
            setTimeout(() => {
                document.getElementById("collectionInfoArea" + id).style.opacity = "100%";
            }, 100);
        }
    }

    async function claimRoyalties() {
        if (((props.tokenBalance / (10 ** 18)) * props.ethPrice).toFixed(2) < 1) {
            setError("minimum claim amount is $1.00");
            setTimeout(() => {
                setError("");
            }, 2000);
        } else {
            try {
                setLoading(true)
                const { data } = await axios.post("/tx/claim-royalties", { userId: props.user._id, tokenId: props.tokenId });
                if (data.success) {
                    setSuccess(true);
                }
                console.log(data);
            } catch (error) {
                console.log(error);
            }
        }
    }

    function closeClaim() {
        props.refreshData();
        setSuccess(false)
        setLoading(false);
    }

    return (
        <div>
            {success ? <Confetti element={""} claimAmount={claimAmount} closeClaim={() => closeClaim()} /> : null}
            <div className="collectionItem" id={open ? "collectionItemOpen" : ""} onClick={() => setOpen(!open)}>
                <div className="collectionItemTop">
                    <img className="collectionItemImg" src={props.user.profileImg}></img>
                    <div onClick={open ? (e) => { e.stopPropagation(); history.push("/user/" + props.user.username); } : null}>
                        <div>
                            {props.user.username}
                        </div>
                        <div className="claimNowText">
                            {((props.tokenBalance / (10 ** 18)) * props.ethPrice).toFixed(2) >= 1 && !props.public ? "claim now: $" + ((props.tokenBalance / (10 ** 18)) * props.ethPrice).toFixed(2) : null}
                        </div>
                    </div>
                    {props.tokenId < 100 ?
                        <div className="nftLevelBadge" id="gold">
                            <img src={gold} className="nftBadgeIcon" id="goldIcon"></img><div>gold</div>
                        </div>
                        :
                        null
                    }
                    <svg id="learnMoreIcon" xmlns="https://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                    </svg>
                </div>
                <div className="collectionInfoArea" id={"collectionInfoArea" + props.index}>
                    <div className="collectionInfoBg">
                        <img className="collectionImgMain" src={props.user.profileImg}></img>
                        <div className="collectionInfo">
                            {/* <div id="collectionName">
                            {props.user.username}
                        </div> */}
                            {/* <div className="collectionText">
                            Address: 0x5E52D1...A032
                        </div> */}
                            <div className="collectionText">
                                <mark className="collectionLabel">Royalty:</mark> {(20 / props.collectorCount).toFixed(2)}%
                            </div>
                            <div className="collectionText">
                                <mark className="collectionLabel">Claimed:</mark> ${props.totalEarned ? (props.totalEarned).toFixed(2) : 0}
                            </div>
                            <div className="collectionText">
                                <mark className="collectionLabel">Claim now:</mark> ${((props.tokenBalance / (10 ** 18)) * props.ethPrice).toFixed(2)}
                            </div>
                            <div className="collectionTextSub">
                                collected: {moment(props.collectDate).format('l')}
                            </div>
                        </div>
                    </div>
                    {props.public ?
                        <div className="viewProfileBtn" onClick={(e) => { e.stopPropagation(); history.push("/user/" + props.user.username); }}>
                            view profile
                            <svg width="15" height="15" fill="none" viewBox="0 0 24 24">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M17.25 15.25V6.75H8.75"></path>
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M17 7L6.75 17.25"></path>
                            </svg>
                        </div>
                        :
                        !loading ?
                            error ?
                                <div className="claimRoyaltiesBtnDisabled" onClick={(e) => { e.stopPropagation(); }}>
                                    {error}
                                </div>
                                :
                                <div className="claimRoyaltiesBtn" onClick={(e) => { e.stopPropagation(); claimRoyalties() }}>
                                    claim royalties
                                </div>
                            :
                            <div className="claimRoyaltiesLoading" onClick={(e) => { e.stopPropagation(); }}>
                                <div id="loaderMessage">
                                    {success ? "claimed" : "loading"}
                                </div>
                                {success ?
                                    <svg id="checker" xmlns="https://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                    </svg>
                                    :
                                    <div className="loaderBtn">

                                    </div>
                                }
                            </div>
                    }
                </div>
            </div>
        </div>
    );
}