import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import HeaderMain from "../../components/HeaderMain";
import PaywallOptions from "./PaywallOptions";
import CollectorOption from "./CollectorOption";
import VideoUploadHandler from "./VideoUploadHandler";
import ThumbnailUploadHandler from "./ThumbnailUploadHandler";
import { AuthContext } from "../../context/AuthContext";
import { useHistory } from "react-router-dom";
import "./styles/createStyle.css";
import BottomNavBlock from "../../components/BottomNavBlock";

export default function Create() {
    const history = useHistory();
    const { isAuthenticated, setIsAuthenticated, user } = useContext(AuthContext);
    const [accessType, setAccessType] = useState("");
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [videoUrl, setVideoUrl] = useState("");
    const [thumbUrl, setThumbUrl] = useState("");
    const [price, setPrice] = useState(0);
    const [accessToken, setAccessToken] = useState({});
    const [tokenLink, setTokenLink] = useState("");
    const [paywallChain, setPaywallChain] = useState("mainnet");
    const [gateChain, setGateChain] = useState("mainnet");

    const [loading, setLoading] = useState("");

    function scrollTo(id) {
        setTimeout(() => {
            document.getElementById(id).scrollIntoView({ behavior: "smooth" });
        }, 400);
    }

    useEffect(() => {
        if (accessType) {
            scrollTo(accessType + "Access");
        }
    }, [accessType]);


    async function submitPost() {
        if (!loading) {
            // check for all video fields complete and if accesstype data is complete
            if (videoUrl && thumbUrl && title && description && ((accessType === "paywall" && price > 0) || accessType === "gate" || accessType === "all" || accessType === "collectors")) {
                setLoading("true");
                let chain = "";

                // get timestamp data
                let duration = document.getElementById("uploadPlayer").duration;
                var hours = Math.floor(duration / 3600);
                var minutes = Math.floor((duration - (hours * 3600)) / 60);
                var seconds = Math.floor(duration - (hours * 3600) - (minutes * 60));
                var timestamp = '';
                if (hours > 0) {
                    timestamp += ('0' + hours).slice(-2) + ':';
                }
                timestamp += ('0' + minutes).slice(-2) + ':' + ('0' + seconds).slice(-2);

                let videoPrice = 0;
                if(accessType === "paywall") {
                    videoPrice = parseFloat(price).toFixed(2);
                }

                let newVideo = { user, userID: user._id, videoSrc: videoUrl, thumbSrc: thumbUrl, title, description, accessType, accessToken, price: videoPrice, chain, tokenLink, timestamp }
                
                try {
                    const data = await axios.post("/media/new-video", { newVideo });
                    console.log(data.data);
                    setTimeout(() => {
                        setLoading("done");
                        setTimeout(() => {
                            history.push("/account");
                        }, 800);
                    }, 500);
                } catch (error) {
                    setLoading("");
                    setIsAuthenticated(false);
                    history.push("/");
                }

            } else {
                alert("missing data");
            }
        }
    }

    return (
        <div>
            <HeaderMain />
            <div id="loadingBlank">

            </div>
            <div id="createFlex">
                <div id="creationSpace">
                    <div id="createTitle">
                        Create a video
                    </div>

                    <div className="createInputLabel">
                        Video Title
                    </div>
                    <div className="createInputArea">
                        <div className="maxTitleCount">
                            {title.length}/70
                        </div>
                        <input className="createInput" value={title} maxlength={70} onChange={(e) => setTitle(e.target.value)}></input>
                    </div>

                    <div id="uploadArea">
                        <VideoUploadHandler setVid={(url) => setVideoUrl(url)} />
                    </div>
                    <div id="uploadOptionsFlex">
                        <div id="uploadCreationDate">
                            <div>
                                Created
                            </div>
                            <div id="uploadDate">
                                {moment(Date.now()).format("ll")}
                            </div>
                        </div>
                    </div>


                    <div className="createThumbLabel">
                        <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M4.75 16L7.49619 12.5067C8.2749 11.5161 9.76453 11.4837 10.5856 12.4395L13 15.25M10.915 12.823C11.9522 11.5037 13.3973 9.63455 13.4914 9.51294C13.4947 9.50859 13.4979 9.50448 13.5013 9.50017C14.2815 8.51598 15.7663 8.48581 16.5856 9.43947L19 12.25M6.75 19.25H17.25C18.3546 19.25 19.25 18.3546 19.25 17.25V6.75C19.25 5.64543 18.3546 4.75 17.25 4.75H6.75C5.64543 4.75 4.75 5.64543 4.75 6.75V17.25C4.75 18.3546 5.64543 19.25 6.75 19.25Z"></path>
                        </svg>
                        <div className="thumbLabelText">
                            Thumbnail
                        </div>
                    </div>
                    <div className="thumbLabelSub">
                        Choose the thumbnail that is displayed before the video plays
                    </div>
                    <div id="thumbnailSelector">
                        <div id="thumbnailGroup">
                            <ThumbnailUploadHandler setThumb={(url) => setThumbUrl(url)} />
                        </div>
                    </div>



                    <div className="createThumbLabel">
                        <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M4.75 16L7.49619 12.5067C8.2749 11.5161 9.76453 11.4837 10.5856 12.4395L13 15.25M10.915 12.823C11.9522 11.5037 13.3973 9.63455 13.4914 9.51294C13.4947 9.50859 13.4979 9.50448 13.5013 9.50017C14.2815 8.51598 15.7663 8.48581 16.5856 9.43947L19 12.25M6.75 19.25H17.25C18.3546 19.25 19.25 18.3546 19.25 17.25V6.75C19.25 5.64543 18.3546 4.75 17.25 4.75H6.75C5.64543 4.75 4.75 5.64543 4.75 6.75V17.25C4.75 18.3546 5.64543 19.25 6.75 19.25Z"></path>
                        </svg>
                        <div className="thumbLabelText">
                            Description
                        </div>
                    </div>
                    <div className="thumbLabelSub">
                        Add a brief description about your video
                    </div>
                    <div id="descriptionInputArea">
                        <div id="maxDescriptionCount">
                            {description.length}/240
                        </div>
                        <textarea id="descriptionInput" value={description} maxlength={240} onChange={(e) => setDescription(e.target.value)}></textarea>
                    </div>



                    <div>
                        <div className="createThumbLabel">
                            <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M5.75 11.75C5.75 11.1977 6.19772 10.75 6.75 10.75H17.25C17.8023 10.75 18.25 11.1977 18.25 11.75V17.25C18.25 18.3546 17.3546 19.25 16.25 19.25H7.75C6.64543 19.25 5.75 18.3546 5.75 17.25V11.75Z"></path>
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M7.75 10.5V10.3427C7.75 8.78147 7.65607 7.04125 8.74646 5.9239C9.36829 5.2867 10.3745 4.75 12 4.75C13.6255 4.75 14.6317 5.2867 15.2535 5.9239C16.3439 7.04125 16.25 8.78147 16.25 10.3427V10.5"></path>
                            </svg>
                            <div className="thumbLabelText">
                                Access
                            </div>
                        </div>
                        <div className="thumbLabelSub">
                            Select who has access to your video
                        </div>
                        <div id="accessOptionArea">

                            <div className="accessOption" id={accessType === "all" ? "allAccess" : ""} style={accessType === "all" ? { border: "solid 1px var(--green)" } : null}>
                                <div className="accessOptionTop">
                                    <div className="iconBg">
                                        <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M5.78168 19.25H13.2183C13.7828 19.25 14.227 18.7817 14.1145 18.2285C13.804 16.7012 12.7897 14 9.5 14C6.21031 14 5.19605 16.7012 4.88549 18.2285C4.773 18.7817 5.21718 19.25 5.78168 19.25Z"></path>
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M15.75 14C17.8288 14 18.6802 16.1479 19.0239 17.696C19.2095 18.532 18.5333 19.25 17.6769 19.25H16.75"></path>
                                            <circle cx="9.5" cy="7.5" r="2.75" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></circle>
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M14.75 10.25C16.2688 10.25 17.25 9.01878 17.25 7.5C17.25 5.98122 16.2688 4.75 14.75 4.75"></path>
                                        </svg>
                                    </div>
                                    <div className="optionTitle">
                                        everyone
                                    </div>
                                    <div onClick={() => setAccessType("all")} className="accessSelectBtn" id={accessType === "all" ? "accessBtn" : "accessSelectedBtn"}>
                                        {accessType === "all" ? "selected" : "select"}
                                    </div>
                                </div>
                            </div>

                            <div className="accessOption" id={accessType === "gate" ? "gateAccess" : ""}>
                                <div className="accessOptionTop">
                                    <div className="iconBg">
                                        <svg xmlns="https://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 5.25a3 3 0 013 3m3 0a6 6 0 01-7.029 5.912c-.563-.097-1.159.026-1.563.43L10.5 17.25H8.25v2.25H6v2.25H2.25v-2.818c0-.597.237-1.17.659-1.591l6.499-6.499c.404-.404.527-1 .43-1.563A6 6 0 1121.75 8.25z" />
                                        </svg>
                                    </div>
                                    <div>
                                        <div className="optionTitle">
                                            collectors
                                        </div>
                                    </div>
                                    <div onClick={() => setAccessType("gate")} className="accessSelectBtn" id={accessType === "gate" ? "accessBtn" : "accessSelectedBtn"}>
                                        {accessType === "gate" ? "selected" : "select"}
                                    </div>
                                </div>
                                <div id="accessOptionPaywall">
                                    <CollectorOption setChain={(chain) => setPaywallChain(chain)} setAmount={(amount) => setPrice(amount)} style={accessType === "gate" ? { display: "initial" } : { display: "none" }} />
                                </div>
                            </div>

                            <div className="accessOption" id={accessType === "paywall" ? "paywallAccess" : ""}>
                                <div className="accessOptionTop">
                                    <div className="iconBg">
                                        <svg xmlns="https://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 5.25a3 3 0 013 3m3 0a6 6 0 01-7.029 5.912c-.563-.097-1.159.026-1.563.43L10.5 17.25H8.25v2.25H6v2.25H2.25v-2.818c0-.597.237-1.17.659-1.591l6.499-6.499c.404-.404.527-1 .43-1.563A6 6 0 1121.75 8.25z" />
                                        </svg>
                                    </div>
                                    <div>
                                        <div className="optionTitle">
                                            paywall
                                        </div>
                                    </div>
                                    <div onClick={() => setAccessType("paywall")} className="accessSelectBtn" id={accessType === "paywall" ? "accessBtn" : "accessSelectedBtn"}>
                                        {accessType === "paywall" ? "selected" : "select"}
                                    </div>
                                </div>
                                <div id="accessOptionPaywall">
                                    <PaywallOptions setChain={(chain) => setPaywallChain(chain)} setAmount={(amount) => setPrice(amount)} style={accessType === "paywall" ? { display: "initial" } : { display: "none" }} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="paymentExplainer">
                        Creators earn 70% of all revenue. Collectors recieve 20% and venue recieves 10%.
                    </div>
                    {/* <div id="missingDataMsg">
                        * missing data
                    </div> */}
                    <div id="submitVideoBtn" onClick={() => submitPost()}>
                        {loading ? loading === "done" ?
                            <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M5.75 12.8665L8.33995 16.4138C9.15171 17.5256 10.8179 17.504 11.6006 16.3715L18.25 6.75"></path>
                            </svg>
                            :
                            <div className="loaderFlex3">
                                <div className="loader3">
                                </div>
                            </div>
                            :
                            <div>
                                publish video
                            </div>
                        }
                    </div>
                </div>
            </div>
            <BottomNavBlock />
        </div >
    );
}