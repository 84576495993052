import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import BuyCard from "./BuyCard";
import "./styles/depositEthStyle.css";
const QRCode = require("qrcode");

export default function DepositEth(props) {
    const { user, balance, ethPrice, getBalance } = useContext(AuthContext);
    const [code, setCode] = useState("");
    const [payWithCard, setPayWithCard] = useState(false);
    const [copied, setCopied] = useState(false);

    useEffect(() => {
        if (user) {
            QRCode.toDataURL(user.address, function (err, url) {
                console.log(url)
                setCode(url);
            });
        }
    }, []);

    useEffect(() => {
        if(copied) {
            setTimeout(() => {
                setCopied(false);
            }, 1000);
        }
    }, [copied])

    if (payWithCard) {
        return (
            <BuyCard close={() => props.close()} />
        );
    }

    function copyCode() {
        setCopied(true)
        navigator.clipboard.writeText(user.address)
            .then(() => {
                console.log('Copied to clipboard:');
            })
            .catch(err => {
                console.error('Error copying to clipboard:', err);
            });
    }

    return (
        <div id="buyCardOuter">
            <div id="modalBlur" onClick={() => props.close()}></div>
            <div id="buyCard">
                <svg onClick={() => props.close()} id="closeCards" xmlns="https://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <div id="buyCardTitle">
                    Deposit eth
                </div>
                <div id="qrCodeFlex">
                    <img id="walletQRImg" src={code}></img>
                    <div id="depositRight">
                        <div className="depositData">
                            <mark className="walletInfoLabel">Address:</mark> {user?.address.slice(0, 5) + "..." + user?.address.slice(user?.address.length - 5, user?.address.length)}
                        </div>
                        <div className="depositData">
                            <mark className="walletInfoLabel">Balance:</mark> ${((balance / 10 ** 18) * ethPrice).toFixed(2)}
                        </div>
                        <div className="depositData">
                            <mark className="walletInfoLabel">Network:</mark> base
                        </div>

                        <div id="depositBtns">
                            <div id="copyDeposit" onClick={() => copyCode()}>
                                {copied ? "copied" : "copy address"}
                            </div>
                            <div id="copySeperator">
                                ‧
                            </div>
                            <div id="refreshBalance" onClick={() => getBalance()}>
                                refresh
                            </div>
                        </div>

                    </div>
                </div>
                <div id="tipOrFlex">
                    <div className="tipOrLine">

                    </div>
                    <div>
                        or
                    </div>
                    <div className="tipOrLine">

                    </div>
                </div>
                <div id="checkoutBtn" onClick={() => setPayWithCard(true)}>
                    pay with card
                </div>
            </div>
        </div>
    );
}